import React, { useState } from 'react';

import { Dropdown, Checkbox, Space, Button } from 'antd';
import { DownOutlined, LeftOutlined } from '@ant-design/icons';
import { Exportxlsx, ExcelHeaderDefinitions } from 'utils/ExportXLS';

type Props = {
    data: any;
    nomComplet: string;
    nomFichier: string;
    definitions: any | ExcelHeaderDefinitions;
    className?: string;
    disabled?: boolean;
};

const ExportSelect = (props: Props) => {
    const [openDrop, setOpenDrop] = useState<boolean>(false);
    const [selectDataExport, setSelectDataExport] = useState<any[]>([]);
    const items = [
        {
            key: '0',
            label: <div className="border-b-[1px] pb-1 ">Sélectionner les données à exporter</div>,
        },
    ];

    function valueCheckbox(h: any) {
        const bool = selectDataExport.filter((e) => e === h);
        return bool.length === 1;
    }
    for (let index = 0; index < props.definitions.length; index++) {
        items.push({
            key: `${index + 1}`,
            label: (
                <Checkbox
                    checked={valueCheckbox(props.definitions[index].header)}
                    onChange={() => checkData(props.definitions[index].header)}
                >
                    {Object.values(props.definitions[index].header)}
                </Checkbox>
            ),
        });
    }
    items.push({
        key: `${props.definitions.length + 1}`,
        label: (
            <div className="border-t-[1px] pt-3 mt-2">
                <Button
                    className="!ml-5 !min-w-[120px]"
                    onClick={() => {
                        selectDataExport.length === props.definitions.length ? setSelectDataExport([]) : selectAll();
                    }}
                >
                    {selectDataExport.length === props.definitions.length ? 'Tout décocher' : 'Tout cocher'}
                </Button>
                <Button disabled={selectDataExport.length === 0} className="!ml-5" onClick={() => exportDoc()}>
                    Exporter
                </Button>
            </div>
        ),
    });

    function checkData(value: any) {
        const index = selectDataExport.findIndex((e) => e === value);
        if (index === -1) return setSelectDataExport((prevData) => [...prevData, value]);
        return setSelectDataExport(selectDataExport.filter((e) => e !== value));
    }
    function exportDoc() {
        const tableauExport: ExcelHeaderDefinitions = [];

        for (let index = 0; index < props.definitions.length; index++) {
            if (selectDataExport.includes(props.definitions[index].header)) {
                tableauExport.push(props.definitions[index]);
            }
        }
        Exportxlsx(props.data, props.nomComplet, props.nomFichier, tableauExport);
        setOpenDrop(false);
    }

    function selectAll() {
        const t: any = [];
        for (let index = 0; index < props.definitions.length; index++) {
            t.push(props.definitions[index].header);
        }
        setSelectDataExport(t);
    }

    return (
        <Dropdown
            disabled={props.disabled ?? false}
            className={`mr-5  ${props.className}`}
            open={openDrop}
            placement="bottom"
            dropdownRender={() => (
                <div className="bg-white p-2 rounded-xl border-solid border-2 ">
                    {items.map((row, i) => (
                        <div key={i}>{row.label}</div>
                    ))}
                </div>
            )}
        >
            <Button onClick={() => setOpenDrop(!openDrop)}>
                <Space>
                    Export Excel
                    {openDrop ? <LeftOutlined /> : <DownOutlined />}
                </Space>
            </Button>
        </Dropdown>
    );
};

export default ExportSelect;
