/* eslint-disable @typescript-eslint/no-explicit-any */
import { Link, useParams } from 'react-router-dom';
import React, { useContext, useEffect, useState } from 'react';
import { FaFile, FaFlagCheckered, FaWarehouse } from 'react-icons/fa';
import classNames from 'classnames';

import { ColumnsType } from 'antd/lib/table';
import { Table } from 'antd';

import Card from 'components/molecules/Card';
import Button from 'components/atoms/Buttons';
import Select from 'components/molecules/Inputs/Select';
import Notification from 'components/atoms/Notifications';

import API from 'constants/API';
import Routes from 'constants/Routes';
import { GET, POST } from 'utils/Fetch';
import { NotificationContext } from 'contexts/Notifications';
import useOptions from 'utils/Hook/GetOptions';
import dayjs from 'dayjs';
import { ModelColis, ModelColisHistory } from 'models/ModelTypeData';
import IconeMap from 'components/organisms/IconeMap';

const Package = () => {
    const { id = '' } = useParams<{ id: string }>();
    const { state, dispatch } = useContext(NotificationContext);
    const [colis, setColis] = useState<ModelColis>();

    const { statusOptions } = useOptions({ getStatus: 'colis' });

    const [showSelect, setShowSelect] = useState<boolean>(false);
    const [changeStatus, setChangeStatus] = useState<{ label: string; value: string }>({ label: '', value: '' });

    const GetPackage = async () => {
        const response = await GET(API.PACKAGE + id);
        if (!response.status) return;
        setColis(response.data);
    };

    useEffect(() => {
        GetPackage();
    }, []);

    const onSubmit = async (data: { label: string; value: string }) => {
        const body = {
            fk_colis: +id,
            fk_etat: +data.value,
        };

        const { status, message } = await POST(API.PACKAGES_STATUS, body);
        if (!status) return dispatch({ message, status });
        dispatch({ message, status });
        GetPackage();
        setShowSelect(false);
    };
    const columnsHistoriqueColis: ColumnsType<ModelColisHistory> = [
        {
            title: 'Date',
            render: (text, props) => dayjs(props.lastHistoryEtat).format('DD/MM/YYYY HH:mm'),
            defaultSortOrder: 'descend',
        },
        {
            title: 'Etat',
            render: (text, props) => (
                <>
                    <span
                        className={classNames('text-sm', {
                            'text-green-500': props.couleur === 'success',
                            'text-purple-600': props.couleur === 'secondary',
                            'text-blue-500': props.couleur === 'info',
                            'text-orange-500': props.couleur === 'warning',
                            'text-red-500': props.couleur === 'error',
                        })}
                    >
                        {props.libelleEtat}
                    </span>
                    <span> {dayjs(props.lastHistoryEtat).format('DD/MM/YYYY HH:mm')}</span>
                    <IconeMap classNames="float-right" longitude={props.longitude} latitude={props.latitude} />
                </>
            ),
        },
    ];

    return (
        <section className="container mx-auto my-5 px-2 grid grid-cols-1 lg:grid-cols-6 gap-5">
            <h2 className="text-2xl text-center lg:col-span-2">
                Colis <span className="text-blue-700">{colis?.numero_colis}</span>
            </h2>
            <Card className="lg:col-span-2 lg:col-start-1" icon={FaFile} info={'Ajouté le ' + dayjs(colis?.dateCreation).format('DD/MM/YYYY HH:mm')}>
                <Link to={Routes.DOCUMENTS + '/' + colis?.fk_document} className="text-purple-900 hover:underline">
                    {'Document ' + colis?.numero_document}
                </Link>
            </Card>
            <Card className="lg:col-span-2 lg:col-start-3" icon={FaWarehouse} info={'En provenance de'}>
                <Link to={Routes.COMPANY + '/' + colis?.fk_tiersSource} className="text-purple-900 hover:underline">
                    {colis?.raisonSocialeTiersSource}
                </Link>
            </Card>
            <Card className="lg:col-span-2 lg:col-start-5" icon={FaFlagCheckered} info={'À destination de'}>
                <Link to={Routes.COMPANY + '/' + colis?.fk_tiersDestination} className="text-purple-900 hover:underline">
                    {colis?.raisonSocialeTiersDestination}
                </Link>
            </Card>
            <div className="lg:col-start-1 lg:col-span-4 row-span-6 flex flex-col">
                <p>Historique de colis</p>
                {/* <TableSuppr columns={columns} data={colis.packageHistories} /> */}
                {colis?.history && <Table columns={columnsHistoriqueColis} dataSource={colis?.history} pagination={false} />}
            </div>
            <div className="py-5 lg:col-start-5 lg:col-span-2 ">
                <Notification message={state.message} status={state.status} />
                {showSelect && (
                    <>
                        <Select
                            name="status"
                            option={statusOptions}
                            className="py-2"
                            isMulti={false}
                            onChange={(e: { label: string; value: string }) => setChangeStatus(e)}
                        />
                        <Button className="w-full flex items-center justify-center" variant="secondary" onClick={() => onSubmit(changeStatus)} bold>
                            Appliquer le changement
                        </Button>
                    </>
                )}
                <Button type="button" className="w-full flex items-center justify-center mt-5" onClick={() => setShowSelect(!showSelect)} bold>
                    {showSelect ? <p>Ne pas changer l&apos;état</p> : <p>Changer l&apos;état</p>}
                </Button>
            </div>
        </section>
    );
};

export default Package;
