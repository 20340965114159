import React, { FunctionComponent, Fragment } from 'react';
import { FaMapMarkedAlt } from 'react-icons/fa';
import { QuestionOutlined } from '@ant-design/icons';

import TooltipCursor from 'components/atoms/Tooltip';

type coordonnee = {
    longitude: string;
    latitude: string;
    classNames?: string;
};
const IconeMap: FunctionComponent<coordonnee> = ({ longitude, latitude, classNames }) => {
    let t: number | undefined | null;
    if (typeof longitude == 'string') {
        t = parseInt(longitude);
    } else {
        t = longitude;
    }

    switch (t) {
        case null:
        case undefined:
            return <></>;

        case 0:
            return (
                <div className={!classNames ? '' : ''.concat(classNames)}>
                    <TooltipCursor content="Coordonnées indisponibles car l'appareil ne les transmet pas" delay={200}>
                        <QuestionOutlined />
                    </TooltipCursor>
                </div>
            );

        default:
            return (
                <a
                    className={!classNames ? 'text-sm text-purple-900 hover:underline' : 'text-sm text-purple-900 hover:underline '.concat(classNames)}
                    target="_blank"
                    href={`https://www.google.com/maps/search/?api=1&query=${longitude},${latitude}`}
                    rel="noreferrer"
                >
                    <FaMapMarkedAlt className="text-lg" />
                </a>
            );
    }
};

export default IconeMap;
