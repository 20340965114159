import React, { FunctionComponent, useContext, useEffect, useState } from 'react';

import Button from 'components/atoms/Buttons';

import { useForm } from 'react-hook-form';
import { GET, PATCH, POST } from 'utils/Fetch';
import API from 'constants/API';
import { NotificationContext } from 'contexts/Notifications';
import { days } from 'constants/Options';
import Input from 'components/atoms/Form/input';
import { Days, FormatOptionSchedule, ReFormatSchedule, Schedule } from 'models/UsersBuilder/UserSchedule';
import Select from 'components/atoms/Form/Select';
import { ModelUserHorairesListe } from 'models/ModelTypeData';
import { modelUserHorairesListe } from 'models/DefaultTypeData';

interface ModalProps {
    open: boolean;
    setOpen: React.Dispatch<React.SetStateAction<boolean>>;
    schedule: ModelUserHorairesListe;
    setSchedule: React.Dispatch<React.SetStateAction<ModelUserHorairesListe>>;
}

const SchedulForm: FunctionComponent<ModalProps> = ({ open = false, setOpen, schedule, setSchedule }) => {
    const { dispatch } = useContext(NotificationContext);

    const [deliverers, setDeliverers] = useState<{ label: string; value: string }[]>([{ label: '', value: '' }]);

    const { register, errors, handleSubmit, reset } = useForm<Schedule>({
        defaultValues: schedule,
        mode: 'onSubmit',
    });

    useEffect(() => {
        if (!open) return;

        GetDeliverer();
        document.addEventListener('keydown', function (event) {
            if (event.key === 'Escape') {
                setOpen(false);
            }
        });
    }, [open]);

    const GetDeliverer = async () => {
        const { status, message, ...response } = await GET(API.USER_LIST);
        if (!status) return dispatch({ status, message });
        setDeliverers(FormatOptionSchedule(response));
        reset({ ...schedule });
    };

    const onSubmit = async (data: Days) => {
        let response;
        const newSchedule = ReFormatSchedule({ ...data });
        if (schedule.id !== 0) {
            newSchedule.id = schedule.id;
            response = await PATCH(API.USER_SCHEDULE, newSchedule);
        } else {
            response = await POST(API.USER_SCHEDULE, newSchedule);
        }
        const { status, message } = response;
        if (!status) return dispatch({ message, status });
        dispatch({ message, status });
        ResetPropsCloseModal();
    };

    const ResetPropsCloseModal = () => {
        setSchedule(modelUserHorairesListe);
        setOpen(false);
    };
    return (
        <>
            {open && (
                <>
                    <div className="justify-center items-center flex overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none">
                        <div className="relative w-auto my-6 mx-auto max-w-sm">
                            <div className="border-0 rounded-lg shadow-lg relative flex flex-col w-full bg-white outline-none focus:outline-none">
                                <div className="flex items-start justify-between p-5 border-b border-solid border-blueGray-200 rounded-t">
                                    <h3 className="text-xl font-semibold flex flex-col">
                                        {schedule.id === 0 ? "Création d'horaire" : "Modifier l'horaire:"}
                                        {schedule.id !== 0 && <span className="text-sm text-gray-600">{schedule.concatNomPrenom}</span>}
                                    </h3>
                                </div>
                                <div className="relative p-6 flex-auto">
                                    <form id="AbsentForm" autoComplete="off" onSubmit={handleSubmit(onSubmit)}>
                                        {schedule.id === 0 && <Select label="Livreur" name="userId" register={register} options={deliverers} />}

                                        {days.map((day) => {
                                            return (
                                                <Input
                                                    key={day.label}
                                                    label={day.label}
                                                    type="text"
                                                    name={`days.${day.value}`}
                                                    placeholder="Exemple: 08:00-14:00"
                                                    messageError="Horaire n'est pas dans le bon format"
                                                    register={register}
                                                    errors={errors}
                                                />
                                            );
                                        })}
                                    </form>
                                </div>
                                <div className="flex items-center justify-end p-6 border-t border-solid border-blueGray-200 rounded-b">
                                    <Button onClick={() => ResetPropsCloseModal()} variant="secondary">
                                        Annuler
                                    </Button>
                                    <Button form="AbsentForm" type="submit" variant="primary">
                                        {schedule.id === 0 ? 'Créer' : 'Modifier'}
                                    </Button>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="opacity-25 fixed inset-0 z-40 bg-black" />
                </>
            )}
        </>
    );
};

export default SchedulForm;
