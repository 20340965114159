import React, { FunctionComponent } from 'react';

import { Input } from 'antd';

interface DestinatairesSourcesProps {
    data: any[] | undefined;
    dataFiltre: any;
    classNames?: string;
}

const RechercheTable: FunctionComponent<DestinatairesSourcesProps> = ({ data, dataFiltre, classNames }) => {
    function arraySearch(value: any) {
        if (data && data.length > 0) {
            const filterTable = data.filter((o: any) =>
                Object.keys(o).some((k) => String(o[k]).toLowerCase().includes(value.target.value.toLowerCase())),
            );
            return dataFiltre(filterTable);
        }
    }

    return <Input.Search className={classNames} allowClear placeholder="Recherche" onChange={arraySearch} />;
};

export default RechercheTable;
