import React, { useContext, useEffect, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { FaTrash } from 'react-icons/fa';

import { ColumnsType } from 'antd/lib/table';
import { Table, Select } from 'antd';

import Button from 'components/atoms/Buttons';
import Modal from 'components/organisms/Modal';

import API from 'constants/API';
import { GET } from 'utils/Fetch';
import Routes from 'constants/Routes';
import { UPLOAD } from 'utils/Fetch/upload';
import { NotificationContext } from 'contexts/Notifications';
import { ModelTourneeListe } from 'models/ModelTypeData';
import RechercheTable from 'components/organisms/rechercheTable';

const TourList = () => {
    const { dispatch } = useContext(NotificationContext);
    const [tourneeListe, setTourneeListe] = useState<ModelTourneeListe[]>([]);
    const [copieData, setCopieData] = useState<ModelTourneeListe[]>([]);
    const [modalIsOpen, setModalIsOpen] = useState<boolean>(false);
    const [tourId, setTourId] = useState<number>();
    const [supprimer, setSupprimer] = useState<number | null>(0);
    const navigate = useNavigate();

    const GetUserList = async () => {
        let query = '';
        if (supprimer !== null) query = `?supprimer=${supprimer}`;
        const response = await GET(API.TOUR_LIST + query);
        setTourneeListe(response.data);
        setCopieData(response.data);
    };

    useEffect(() => {
        if (!modalIsOpen) GetUserList();
    }, [modalIsOpen, supprimer]);

    const onUpload = async (file: FileList | null) => {
        if (!file) return;
        const { status, message } = await UPLOAD(API.TOUR_UPLOAD_FILE, file);
        if (status) GetUserList();
        return dispatch({ status, message });
    };

    const renderInfo = (a: string, b: string) => {
        switch (true) {
            case !!a && !b:
                return a;
            case !!b && !a:
                return b;
            case !!a && !!b:
                return `${a} - ${b}`;
            default:
                break;
        }
    };

    const columnsTable: ColumnsType<ModelTourneeListe> = [
        {
            title: 'Tournées',
            sorter: (a, b) => (+a.libelleTournee || 0) - (+b.libelleTournee || 0),
            render: (text, props) => (
                <div className="flex justify-between">
                    <span className="flex flex-col justify-between w-full">
                        <Link to={`${Routes.TOUR}/${props.idTournee}`}>
                            <p className="text-sm w-5/6 text-purple-900 hover:underline">{props.libelleTournee}</p>
                        </Link>
                        <p>Livreur: {props.NomPrenomLivreur}</p>
                        {props.identifiant_tournee && (
                            <p className="text-sm italic text-gray-500">{renderInfo(props.identifiant_tournee, props.RaisonSociale)}</p>
                        )}
                    </span>
                    {!props.SuppressedAt && (
                        <button
                            type="button"
                            className="outlined-none text-red-600"
                            onClick={() => {
                                setModalIsOpen(true);
                                setTourId(props.idTournee);
                            }}
                        >
                            <FaTrash />
                        </button>
                    )}
                </div>
            ),
            defaultSortOrder: 'ascend',
        },
        {
            title: 'Description',
            render: (text, props) => (
                <div className="flex justify-between flex-col">
                    <p>{props.Description}</p>
                    <span className="text-sm italic text-gray-500">{props.journees}</span>
                </div>
            ),
        },
    ];
    return (
        <section className="container mx-auto my-5">
            <div className="flex items-end mb-5 ">
                <div className="flex flex-col w-[25%] mr-5">
                    <label className="mr-5">Tournées supprimées : </label>
                    <Select
                        value={supprimer}
                        placeholder="Tournées supprimés"
                        onChange={(value) => setSupprimer(value)}
                        options={[
                            { label: 'Voir les non supprimées', value: 0 },
                            { label: 'Voir les supprimées', value: 1 },
                            { label: 'Voir toutes les tournées', value: null },
                        ]}
                    />
                </div>
                <RechercheTable classNames=" w-[30%] mr-5" data={copieData} dataFiltre={(data: any) => setTourneeListe(data)} />
                <Button onClick={() => navigate(`${Routes.TOUR}/0`)}>Ajouter une tournée</Button>
                <label
                    className="flex items-center justify-center px-4 py-2 mr-1 text-sm rounded-md shadow outline-none bg-white  border-2 border-gray-400 text-gray-400 focus:outline-none hover:border-purple-700 hover:text-purple-700"
                    htmlFor="file-upload"
                >
                    Import
                </label>
                <input id="file-upload" type="file" className="hidden" onChange={({ target }) => onUpload(target.files)} />
            </div>

            <Table
                columns={columnsTable}
                dataSource={tourneeListe}
                pagination={{
                    showTotal: (total: any, range: any) => `${range[0]}-${range[1]} / ${total} `,
                    position: ['topRight', 'bottomRight'],
                    total: tourneeListe?.length,
                    defaultCurrent: 1,
                    defaultPageSize: 20,
                }}
            />
            <Modal open={modalIsOpen} setOpen={setModalIsOpen} url={`${API.TOUR}/${tourId}`} />
        </section>
    );
};

export default TourList;
