import { useForm } from 'react-hook-form';
import React, { FunctionComponent, useContext, useEffect, useState } from 'react';
import { FaTrash } from 'react-icons/fa';
import { useSearchParams } from 'react-router-dom';
import { Link } from 'react-router-dom';
import { Modal, Button } from 'antd';

import { Table } from 'antd';
import { ColumnsType } from 'antd/lib/table';

import Title from 'components/atoms/Title';
import ButtonCustom from 'components/atoms/Buttons';
import Valid from 'components/molecules/Valid';
import Input from 'components/atoms/Form/input';
import Select from 'components/atoms/Form/Select';
import TextArea from 'components/atoms/Form/textArea';
import CheckBox from 'components/molecules/CheckBox';
import Notification from 'components/atoms/Notifications';
import SelectCustom from 'components/molecules/Inputs/Select';

import API from 'constants/API';
import Routes from 'constants/Routes';
import { DELETE, GET, PATCH, POST } from 'utils/Fetch';
import { NotificationContext } from 'contexts/Notifications';
import Company, { ReFormatCompany, CompanyBuilder } from 'models/CompanyBuilder/Company';
import useOptions from 'utils/Hook/GetOptions';
import CloseForm from '../CloseForm';
import { Close, EmptyCloseData } from 'models/CompanyBuilder/CompanyClosure';
import formatSelect from 'models/OptionsBuilder';

interface TiersFormProps {
    id: string;
    tiersInfo: Company;
}

const pharmacy = '2';

const TiersForm: FunctionComponent<TiersFormProps> = ({ id, tiersInfo }) => {
    const { state, dispatch } = useContext(NotificationContext);
    const [showSelect, setShowSelect] = useState<'tournee' | 'etatCle' | null>();
    const { statusOptions: statusOption } = useOptions({ getStatus: 'cle' });
    const [, setParams] = useSearchParams();

    const { companyTypeOptions } = useOptions({ getCompaniesType: true });

    const [loading, setLoading] = useState<boolean>(true);
    const [ClosureModal, setClosureModal] = useState<boolean>(false);
    const [tournee, setTournee] = useState<any[]>([]);
    const [selectTournee, setSelectTournee] = useState<any>();
    const [selectEtatCle, setSelectEtatCle] = useState<any>();
    const [tourneeListe, setTourneeListe] = useState<any[]>([]);
    const [companyClosure, setCompanyClosure] = useState<Close>({
        ...EmptyCloseData,
        RaisonSociale: tiersInfo.name,
        id: tiersInfo.id ? tiersInfo.id : null,
    });
    const { register, reset, handleSubmit, getValues, errors, watch } = useForm<Company>({
        defaultValues: CompanyBuilder(),
    });
    const [open, setOpen] = useState<{ open: boolean; content?: string[] }>({ open: false });

    const setFilesProcess = async () => {
        const { status, message } = await POST(`${API.PROCESS_FILES}/${tiersInfo.id}`);
        if (status)
            setOpen({
                open: true,
                content: message,
            });
    };
    const recupTounees = async () => {
        const tournee = await GET(`${API.TOURNEE_TIER}/${tiersInfo.id}`);
        if (!tournee.status) return dispatch({ status: tournee.status, message: tournee.message });

        setTournee(tournee.data);
        const tourneeListe = await GET(API.TOUR_LIST);
        if (!tourneeListe.status) return dispatch({ status: tourneeListe.status, message: tourneeListe.message });

        let excludeTournee = tourneeListe.data;
        for (let index = 0; index < tournee.data.length; index++) {
            excludeTournee = excludeTournee.filter((e: any) => e.id !== tournee.data[index].fk_tournee);
        }
        excludeTournee.sort((a: any, b: any) => (a.Libelle > b.Libelle ? 1 : b.Libelle > a.Libelle ? -1 : 0));

        setTourneeListe(formatSelect(excludeTournee, 'id', 'Libelle'));
    };
    const associerTournee = async () => {
        const data = {
            idTiers: id,
            fk_tournee: selectTournee.value,
        };
        const { status, message } = await POST(`${API.TOURNEE_ASSOCIATION}`, data);
        if (!status) return dispatch({ status, message });
        recupTounees();
        setShowSelect(null);
    };
    const modificationEtatCle = async () => {
        const data = {
            fk_key: tiersInfo.idCle,
            fk_etat: selectEtatCle.value,
        };
        const { status, message } = await POST(`${API.MODIFIER_ETAT_KEYS}`, data);
        if (!status) return dispatch({ status, message });
        setShowSelect(null);
    };
    const deassocierTournee = async (fk_tournee: number) => {
        const data = {
            idTiers: id,
            fk_tournee: fk_tournee,
        };
        const { status, message } = await DELETE(`${API.TOURNEE_DESASSOCIATION}`, data);
        if (!status) return dispatch({ status, message });
        recupTounees();
    };
    useEffect(() => {
        if (id !== '0') {
            reset({ ...tiersInfo });
            recupTounees();
        }
        isPharmacy();
        setLoading(false);
    }, [companyTypeOptions]);

    const onSubmit = async (companyInfo: Company) => {
        if (companyInfo.access) {
            if (companyInfo.access.openingHours) {
                const schedule = companyInfo.access.openingHours.split('\n');
                companyInfo.access.openingHours = schedule.join(';');
            } else {
                companyInfo.access.openingHours = '';
            }
        }
        companyInfo.id = id === '0' ? null : +id;
        const FormatedTiers = ReFormatCompany(companyInfo);
        let response;
        if (+id === 0) {
            response = await POST(API.TIER, FormatedTiers);
        } else {
            companyInfo.id = +id;
            response = await PATCH(API.TIER, FormatedTiers);
        }
        const { status, message } = response;
        dispatch({ message, status });
        if (!status) return;
        if (response.data) {
            setParams({ id: response.data[0].id });
            isPharmacy();
        }
    };

    const isPharmacy = () => {
        if (id !== '0') {
            return tiersInfo.typeId === pharmacy;
        } else {
            return watch('typeId') === pharmacy;
        }
    };

    const columns: ColumnsType<any> = [
        {
            title: 'Tournée',
            render: (text, props) => {
                return (
                    <div className="flex">
                        <button
                            type="button"
                            className="outlined-none text-red-600 mr-3"
                            onClick={() => deassocierTournee(props.fk_tournee)}
                        >
                            <FaTrash />
                        </button>
                        <div>
                            <Link to={`${Routes.TOUR}/${props.fk_tournee}`}>
                                <span className=" text-purple-900 hover:underline">{props.Libelle}</span>
                            </Link>
                            <br />
                            <span className="text-[0.9em]">chauffeur: {props.NomPrenomLivreur}</span>
                        </div>
                    </div>
                );
            },
        },
        {
            title: 'Description',
            render: (text, props) => {
                return (
                    <div>
                        <span className="float-right">{props.Description}</span>
                        <br />
                        <span className="float-right text-[0.9em]">{props.jours}</span>
                    </div>
                );
            },
        },
    ];

    if (loading) return <p>chargement...</p>;
    return (
        <>
            {/* <Prompt
                when={formIsWhat()}
                message="Votre travail n'est pas sauvegardé! Vous êtes sûr de vouloir quitter ?"
            /> */}
            <Modal
                width={'40%'}
                open={open.open}
                onCancel={() => setOpen({ open: false })}
                footer={[
                    <Button
                        type="primary"
                        className=" !border-blue-700 !bg-blue-700"
                        key="back"
                        onClick={() => setOpen({ open: false })}
                    >
                        Valider
                    </Button>,
                ]}
            >
                <div>
                    <h3 className="text-xl">Information concernant le traitement des fichiers.</h3>
                    <br />
                    <div>
                        {open.content &&
                            open.content.map((row: string, i: number) => (
                                <>
                                    <span key={i}>- {row}</span>
                                    <br />
                                </>
                            ))}
                    </div>
                </div>
            </Modal>
            <form
                id="TierForm"
                autoComplete="off"
                onSubmit={handleSubmit(onSubmit)}
                className="w-full flex justify-center flex-col md:flex-row"
            >
                <div className="w-full md:w-2/5 pr-10 pl-5">
                    <Title text="Information de l'entreprise" />
                    <Select
                        label="Type"
                        name="typeId"
                        messageError="Manque le type"
                        errors={errors}
                        register={register({ required: true })}
                        options={companyTypeOptions}
                    />
                    <Input
                        label="Identifiant"
                        name="login"
                        errors={errors}
                        messageError="Manque l'indentifiant"
                        register={register({ required: true, minLength: 2 })}
                    />
                    <Input
                        label="Raison social"
                        name="name"
                        messageError="Manque le nom de l'entreprise"
                        errors={errors}
                        register={register({ required: true, minLength: 2 })}
                    />
                    <Input label="Complément raison social" name="complementName" register={register} />
                    <Input label="Email" type="email" name="email" register={register} />
                    <Input label="Téléphone 1" type="tel" name="phone" register={register} />
                    <Input label="Téléphone 2" type="tel" name="phone2" register={register} />
                    <Title text="Sécurité" />
                    <Input label="Mot de passe" name="password" type="password" errors={errors} register={register} />
                    <Input
                        label="Répétez le mot de passe"
                        name="repeatePassword"
                        type="password"
                        messageError="le mot de passe n'est pas identique"
                        errors={errors}
                        register={register({
                            validate: (value) => value === getValues('password'),
                        })}
                    />
                    <Title text="Tournées associées" />
                    {tournee.length !== 0 ? (
                        <Table className="mt-5" columns={columns} dataSource={tournee} pagination={false} />
                    ) : (
                        <span>Il n&apos;y a aucune tournée associé à ce tiers.</span>
                    )}
                    <div className="py-5 lg:row-start-4 lg:col-start-5 lg:col-span-2">
                        <Notification message={state.message} status={state.status} />
                        {showSelect === 'tournee' && (
                            <>
                                <SelectCustom
                                    name="etat"
                                    option={tourneeListe}
                                    onChange={(e: any) => setSelectTournee(e)}
                                    className="py-2"
                                    isMulti={false}
                                />
                                <ButtonCustom
                                    className="w-full flex items-center justify-center"
                                    variant="secondary"
                                    onClick={() => associerTournee()}
                                    bold
                                >
                                    Valider l&apos;association
                                </ButtonCustom>
                            </>
                        )}
                        <ButtonCustom
                            type="button"
                            className="w-full flex items-center justify-center mt-5"
                            onClick={() => setShowSelect(showSelect ? null : 'tournee')}
                            bold
                        >
                            {showSelect === 'tournee' ? <p>Retour</p> : <p>Associer une tournée</p>}
                        </ButtonCustom>
                    </div>
                </div>
                <div className="w-full md:w-2/5 pr-10 pl-5">
                    <Title text="Adresse de l'entreprise" />
                    <Valid type={id === '0' ? '1' : watch('typeId')} valid={tiersInfo.location.isGpsValid}>
                        <Input
                            label="Adresse 1"
                            name="location.address"
                            messageError="Manque l'adresse"
                            errors={errors}
                            register={register({ required: true, minLength: 2 })}
                        />
                        <Input label="Adresse 2" name="location.address2" register={register} />
                        <Input
                            label="Code Postal"
                            name="location.postalCode"
                            messageError="Manque le code postale"
                            errors={errors}
                            register={register({ required: true, minLength: 2 })}
                        />
                        <Input
                            label="Ville"
                            name="location.city"
                            messageError="Manque la ville"
                            errors={errors}
                            register={register({ required: true, minLength: 2 })}
                        />
                    </Valid>
                    {isPharmacy() ? (
                        <>
                            <Title text="Accès à l'entreprise" />
                            <Input
                                label="Code de la clé"
                                name="access.keyNumber"
                                messageError="Taper le code de la clé"
                                errors={errors}
                                register={register}
                            />
                            {tiersInfo.idCle && (
                                <div className="py-5 lg:row-start-4 lg:col-start-5 lg:col-span-2">
                                    <Notification message={state.message} status={state.status} />
                                    {showSelect === 'etatCle' && (
                                        <>
                                            <SelectCustom
                                                name="etat"
                                                option={statusOption}
                                                onChange={(e: any) => setSelectEtatCle(e)}
                                                className="py-2"
                                                isMulti={false}
                                            />
                                            <ButtonCustom
                                                className="w-full flex items-center justify-center"
                                                variant="secondary"
                                                onClick={() => modificationEtatCle()}
                                                bold
                                            >
                                                Valider
                                            </ButtonCustom>
                                        </>
                                    )}
                                    <ButtonCustom
                                        type="button"
                                        className="w-full flex items-center justify-center mt-5"
                                        onClick={() => setShowSelect(showSelect ? null : 'etatCle')}
                                        bold
                                    >
                                        {showSelect === 'etatCle' ? (
                                            <p>Retour</p>
                                        ) : (
                                            <p>modifier l&apos;état de la clé</p>
                                        )}
                                    </ButtonCustom>
                                </div>
                            )}
                            <Valid type={id === '0' ? '1' : watch('typeId')} valid={tiersInfo.access.scheduleValid}>
                                <span className="flex flex-col px-2 mb-2 w-full">
                                    <label>Horaire de l&apos;entreprise:</label>
                                    <strong className="text-sm ml-3">
                                        Exemple:
                                        <br /> Lundi au vendredi de 8h45 à 13h et de 14h à 19h30
                                        <br /> LU-VE:08h45-13h00,14h00-19h30
                                        <br /> SA:08h45-13h00
                                    </strong>
                                </span>
                                <TextArea
                                    name="access.openingHours"
                                    register={register}
                                    messageError="Erreur avec les horaires"
                                    errors={errors}
                                    textArea
                                />
                            </Valid>
                            <TextArea label="Commentaire" name="access.comment" register={register} textArea />
                            <ButtonCustom variant="primary" onClick={() => setClosureModal(true)}>
                                Créer une fermeture
                            </ButtonCustom>
                        </>
                    ) : (
                        <div>
                            <Title text="Tournée auto" />
                            <CheckBox
                                label="Tournée Auto"
                                name="autoTour"
                                id={'autoTourCheckBox'}
                                register={register}
                            />
                            {tiersInfo.file.isWaiting && (
                                <div>
                                    <Title text={`${tiersInfo.file.listFile.length} Fichier(s) en attente`} />
                                    {tiersInfo.file.listFile.map((element) => (
                                        <p key={element}>{element}</p>
                                    ))}
                                    <ButtonCustom
                                        className="text-sm mt-5"
                                        bold
                                        variant="primary"
                                        onClick={() => setFilesProcess()}
                                    >
                                        Lancer traitement des fichiers
                                    </ButtonCustom>
                                </div>
                            )}
                        </div>
                    )}
                </div>
            </form>
            <CloseForm
                open={ClosureModal}
                setOpen={setClosureModal}
                closure={companyClosure}
                selectDisabled={true}
                setClosure={setCompanyClosure}
            />
            <div className="flex justify-center py-5">
                <ButtonCustom type="submit" form="TierForm" className="text-base" bold>
                    Enregistrer
                </ButtonCustom>
            </div>
        </>
    );
};

export default TiersForm;
