import React, { useEffect, useState } from 'react';

import dayjs from 'dayjs';
import 'dayjs/locale/fr';
import local from 'antd/es/date-picker/locale/fr_FR';

import { ColumnsType } from 'antd/lib/table';
import { Table, Select, DatePicker } from 'antd';

import API from 'constants/API';
import { GET } from 'utils/Fetch';
import { GetlisteEtat } from 'utils/ListeSelect/statuts';
import RechercheTable from 'components/organisms/rechercheTable';
import { ModelDocumentListe } from 'models/ModelTypeData';
import Routes from 'constants/Routes';
import { Link } from 'react-router-dom';
import classNames from 'classnames';
import { SettingOutlined } from '@ant-design/icons';
import formatSelect from 'models/OptionsBuilder';
import recupQuery from 'utils/Queries/recupQueries';
import { ExcelHeaderDefinitions } from 'utils/ExportXLS';
import ExportSelect from 'components/organisms/ExportSelect';

const { RangePicker } = DatePicker;

const definitionsTableau: ExcelHeaderDefinitions = [
    { header: 'Tiers source', data: 'tsRaisonSociale', type: 'string' },
    { header: 'Tiers destinataire', data: 'tdRaisonSociale', type: 'string' },
    { header: 'Tournée', data: 'libelleTournee', type: 'string' },
    { header: 'Numero', data: 'numeroDocument', type: 'string' },
    { header: 'Date de création', data: 'dateCreation', type: 'date' },
    { header: 'Etat', data: 'libelleEtat', type: 'string' },
    { header: 'Nom du fichier', data: 'filename', type: 'string' },
    { header: 'Nombre de colis', data: 'countColis', type: 'entier' },
];

const DocumentsList = () => {
    const [periode, setPeriode] = useState<string[]>([dayjs().subtract(1, 'week').format('YYYY-MM-DD'), dayjs().format('YYYY-MM-DD')]);
    const [documentsList, setDocumentsList] = useState<ModelDocumentListe[]>([]);
    const [copieData, setCopieData] = useState<ModelDocumentListe[]>([]);
    const [selectListeStatut, setSelectListeStatut] = useState<any[]>([]);
    const [selectListeSources, setSelectListeSources] = useState<any[]>([]);
    const [etat, setEtat] = useState<number[]>(+recupQuery('etat') ? [+recupQuery('etat')] : []);
    const [sources, setSources] = useState<number[]>([]);
    async function getListeSelect() {
        const response = await GET(API.OPTIONS_COMPANIES_SOURCE);
        setSelectListeSources(formatSelect(response.data, 'id', 'RaisonSociale'));
        setSelectListeStatut(await GetlisteEtat('document'));
    }
    const GetDocumentsList = async () => {
        let query = `?debutPeriode=${dayjs(periode[0]).format('YYYY-MM-DD  00:00:00')}&finPeriode=${dayjs(periode[1]).format('YYYY-MM-DD  23:59:59')}`;
        if (etat.length !== 0) query += `&fk_etat=${etat}`;
        if (sources.length !== 0) query += `&fk_tiersSource=${sources}`;
        const response = await GET(`${API.DOCUMENTS_LIST}${query}`);
        if (!response.status) return;
        setDocumentsList(response.data);
        setCopieData(response.data);
    };

    useEffect(() => {
        GetDocumentsList();
    }, [periode, etat, sources]);

    useEffect(() => {
        getListeSelect();
    }, []);

    const columnsTable: ColumnsType<ModelDocumentListe> = [
        {
            title: 'Tiers source',
            sorter: (a, b) => a.tsRaisonSociale.localeCompare(b.tsRaisonSociale),
            render: (text, props) => (
                <Link to={`${Routes.COMPANY}/${props.tsId}`} className="w-full">
                    <p className="text-sm text-purple-900 hover:underline">{props.tsRaisonSociale}</p>
                </Link>
            ),
        },
        {
            title: 'Tiers destinataire',
            sorter: (a, b) => a.tdRaisonSociale.localeCompare(b.tdRaisonSociale),
            render: (text, props) => (
                <Link to={`${Routes.COMPANY}/${props.tdId}`} className="w-full">
                    <p className="text-sm text-purple-900 hover:underline">{props.tdRaisonSociale}</p>
                </Link>
            ),
        },
        {
            title: 'Tournée',
            sorter: (a, b) => (a.libelleTournee && b.libelleTournee ? a.libelleTournee.localeCompare(b.libelleTournee) : 0),
            render: (text, props) => (
                <span className="flex justify-between">
                    <Link to={`${Routes.TOUR}/${props.idTournee}`} className="w-full">
                        <p className="text-sm text-purple-900 hover:underline">{props.libelleTournee}</p>
                    </Link>
                </span>
            ),
        },
        {
            title: 'Numero',
            sorter: (a, b) => a.numeroDocument.localeCompare(b.numeroDocument),
            render: (text, props) => (
                <span className="flex justify-between">
                    <Link to={`${Routes.DOCUMENTS}/${props.idDocument}`} className="w-full">
                        <p className="text-sm text-purple-900 hover:underline">{props.numeroDocument}</p>
                    </Link>
                </span>
            ),
        },
        {
            title: 'Date de création',
            sorter: (a, b) => a.dateCreation.localeCompare(b.dateCreation),
            render: (text, props) => dayjs(props.dateCreation).format('DD/MM/YYYY HH:mm'),
        },
        {
            title: 'Etat',
            sorter: (a, b) => (+a.fk_etat || 0) - (+b.fk_etat || 0),
            render: (text, props) => (
                <span
                    className={classNames('text-sm p-1', {
                        'text-green-500': props.couleur === 'success',
                        'text-purple-600': props.couleur === 'secondary',
                        'text-blue-500': props.couleur === 'info',
                        'text-orange-500': props.couleur === 'warning',
                        'text-red-500': props.couleur === 'error',
                    })}
                >
                    {props.libelleEtat}
                </span>
            ),
        },
        {
            title: 'Nom de fichier',
            sorter: (a, b) => a.numeroDocument.localeCompare(b.numeroDocument),
            render: (text, props) => (
                <div className="flex">
                    <Link to={`${Routes.FILES_HISTORY}/${props.fk_fichier}`}>
                        <p className="text-sm text-left text-purple-900 hover:underline">{props.filename}</p>
                    </Link>
                    {props.fk_fichier && (
                        <Link to={`${Routes.FILES_HISTORYBRUT_ID}?${props.fk_fichier}`}>
                            <SettingOutlined className="!text-green-600 ml-2 mt-0" />
                        </Link>
                    )}
                </div>
            ),
        },
        {
            title: 'Nb colis',
            render: (text, props) => <span className="flex justify-between">{props.countColis}</span>,
        },
    ];

    return (
        <section className="mx-10 my-5">
            <div className="flex items-end">
                <div className="flex flex-col mr-5">
                    <label className="mr-5">Periode : </label>
                    <RangePicker
                        value={[dayjs(periode[0]), dayjs(periode[1])]}
                        format={'DD-MM-YYYY'}
                        locale={local}
                        onChange={(dates, dateStrings) => {
                            if (dateStrings[0]) setPeriode([dateStrings[0].split('-').reverse().join('-'), dateStrings[1].split('-').reverse().join('-')]);
                            else setPeriode([dayjs().subtract(1, 'years').format('YYYY-MM-DD'), dayjs().format('YYYY-MM-DD')]);
                        }}
                        presets={[
                            { label: "Aujourd'hui", value: [dayjs(), dayjs()] },
                            { label: 'Hier', value: [dayjs().subtract(1, 'days'), dayjs().subtract(1, 'days')] },
                            { label: '7 derniers jours', value: [dayjs().subtract(7, 'days'), dayjs()] },
                            { label: '30 derniers jours', value: [dayjs().subtract(30, 'days'), dayjs()] },
                            { label: '90 derniers jours', value: [dayjs().subtract(90, 'days'), dayjs()] },
                            { label: '12 derniers mois', value: [dayjs().subtract(12, 'month'), dayjs()] },
                            { label: 'Ce mois-ci', value: [dayjs().startOf('month'), dayjs().endOf('month')] },
                            { label: 'Le mois dernier', value: [dayjs().startOf('month').subtract(1, 'month'), dayjs().endOf('month').subtract(1, 'month')] },
                        ]}
                    />
                </div>
                <div className="flex flex-col w-[25%] mr-5">
                    <label className="mr-5">Sélection de l’état : </label>
                    <Select value={etat} mode="multiple" placeholder="Sélection de l’état" onChange={(value) => setEtat(value)} options={selectListeStatut} />
                </div>

                <div className="flex flex-col w-[25%] mr-5">
                    <label className="mr-5">Sélection de la source : </label>
                    <Select mode="multiple" placeholder="Sélection de la source" onChange={(value) => setSources(value)} options={selectListeSources} />
                </div>
                <RechercheTable classNames=" w-[30%]" data={copieData} dataFiltre={(data: any) => setDocumentsList(data)} />
                <ExportSelect
                    className="!font-bold ml-5"
                    data={documentsList}
                    nomComplet="documents_Liste.xlsx"
                    nomFichier="documents_Liste"
                    definitions={definitionsTableau}
                />
            </div>

            <Table
                columns={columnsTable}
                dataSource={documentsList}
                pagination={{
                    showTotal: (total: any, range: any) => `${range[0]}-${range[1]} / ${total} `,
                    position: ['topRight', 'bottomRight'],
                    total: documentsList?.length,
                    defaultCurrent: 1,
                    defaultPageSize: 20,
                }}
            />
        </section>
    );
};

export default DocumentsList;
