import React, { useContext, useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { useParams } from 'react-router';

import dayjs from 'dayjs';
import 'dayjs/locale/fr';

import { Table, Button } from 'antd';
import { ColumnsType } from 'antd/lib/table';

import API from 'constants/API';
import { GET } from 'utils/Fetch';
import Routes from 'constants/Routes';

import { FileInformations, fileInformation, DocumentsInformation } from 'models/FileBuilder';
import { NotificationContext } from 'contexts/Notifications';

const File = () => {
    dayjs.locale('fr');
    const { id } = useParams<{ id: string }>();
    const [file, setFile] = useState<FileInformations>(fileInformation);
    const { dispatch } = useContext(NotificationContext);

    const GetFileInfo = async () => {
        const { status, ...response } = await GET(API.FILE + id);
        if (!status) return;
        setFile(response.data[0]);
    };

    async function renvoieFichier() {
        const response = await GET(`${API.RENVOI_FICHIER}?id=${id}`);
        return dispatch({ message: response.message, status: response.status });
    }

    useEffect(() => {
        GetFileInfo();
    }, []);
    const columns: ColumnsType<DocumentsInformation> = [
        {
            title: 'Numéro document',
            dataIndex: 'numeroDocument',
            sorter: (a, b) => a.numeroDocument.localeCompare(b.numeroDocument),
        },
        {
            title: 'Tiers',
            sorter: (a, b) => a.tiersDestination.raisonSociale.localeCompare(b.tiersDestination.raisonSociale),
            render: (text, props) => (
                <Link to={`/tiers/${props.tiersDestination.id}`} target="_blank">
                    <span>{`Serveur ${props.tiersDestination.raisonSociale}`}</span>
                </Link>
            ),
        },
        {
            title: 'Action',
            dataIndex: 'action',
            sorter: (a, b) => a.action.localeCompare(b.action),
        },
        {
            title: 'nd Colis',
            dataIndex: 'nombreColis',
            sorter: (a, b) => (a.nombreColis || 0) - (b.nombreColis || 0),
        },
    ];
    return (
        <div className="bg-white container mx-auto my-5 px-2 ">
            <div className="flex justify-between pt-5 pr-10 pl-10">
                <div className="w-[30%] p-3 border-purple-700 rounded-lg border-2">
                    <span className="text-base uppercase">En provenance de :</span>
                    <Link className=" float-right" to={`${Routes.COMPANY}/${file.fk_tiers}`}>
                        <span className=" text-sm w-5/6 text-purple-900 hover:underline">{file.RaisonSocialeTiers}</span>
                    </Link>
                    <br />
                    <Button
                        onClick={() => renvoieFichier()}
                        form="promotionForm"
                        className="mt-2 float-right  !border-purple-700 !bg-purple-700 !font-bold"
                        type="primary"
                    >
                        Renvoyer le fichier
                    </Button>
                </div>
                <div className="w-[30%] p-3 border-purple-700 rounded-lg border-2">
                    <span className="text-base uppercase">Traité le :</span>
                    <span className="float-right">{dayjs(file.dateTraitement).format('dddd D MMMM YYYY h:mm ')}</span>
                    <br />
                    <span className="text-base uppercase">Par :</span>
                    <span className="float-right">{file.concatNomPrenom ? file.concatNomPrenom : 'Non précisé'}</span>
                </div>
                <div className="w-[20%] p-3 border-purple-700 rounded-lg border-2">
                    <span className="text-base uppercase">Lu : </span>
                    <span className="float-right"> {file.informations.nombreDocuments.lu}</span> <br />
                    <span className="text-base uppercase">Ajouté : </span>
                    <span className="float-right"> {file.informations.nombreDocuments.ajoute}</span>
                </div>
            </div>

            <Table
                columns={columns}
                dataSource={file.informations.documents}
                pagination={{
                    showTotal: (total: any, range: any) => `${range[0]}-${range[1]} / ${total} `,
                    position: ['topRight', 'bottomRight'],
                    total: file.informations.documents?.length,
                    defaultCurrent: 1,
                    defaultPageSize: 20,
                }}
            />
        </div>
    );
};

export default File;
