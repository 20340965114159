import React, { Fragment, useContext, useEffect, useState } from 'react';
import { Link, useParams } from 'react-router-dom';
import { FaFile, FaFlagCheckered, FaWarehouse } from 'react-icons/fa';
import classNames from 'classnames';

import { ColumnsType } from 'antd/lib/table';
import { Table } from 'antd';

import Card from 'components/molecules/Card';
import Button from 'components/atoms/Buttons';
import Select from 'components/molecules/Inputs/Select';
import Notification from 'components/atoms/Notifications';

import API from 'constants/API';
import Routes from 'constants/Routes';
import { GET, POST } from 'utils/Fetch';
import { NotificationContext } from 'contexts/Notifications';
import useOptions from 'utils/Hook/GetOptions';
import dayjs from 'dayjs';
import { FileTextOutlined, SettingOutlined } from '@ant-design/icons';
import { ModelDocument, ModelDocumentColisHistory, ModelDocumentHistory } from 'models/ModelTypeData';

interface Options {
    label: string;
    value: string;
}
const etatDocLivraisonOK = 11;
const etatColiKO = [4, 8, 12];

type RenvoisColisData = {
    fk_tiersSource: string;
    fk_tiersDestination: string;
    numero: string;
    colis: { numero: string; poids: string; id_colis: number }[];
};

const Document = () => {
    const { id = '' } = useParams<{ id: string }>();
    const { state, dispatch } = useContext(NotificationContext);
    const { statusOptions: statusOption } = useOptions({ getStatus: 'document' });
    const [document, setDocument] = useState<ModelDocument>();
    const [showSelect, setShowSelect] = useState<boolean>(false);
    const [status, setStatus] = useState<Options>({ label: '', value: '' });
    const [renvoisColisData, setRenvoisColisData] = useState<RenvoisColisData>();

    const GetDocument = async () => {
        const response = await GET(API.DOCUMENT + id);
        if (!response.status) return dispatch({ status: response.status, message: response.message });
        if (response.data.documentHistory[0].fk_etat === etatDocLivraisonOK) {
            let data;
            for (let index = 0; index < response.data.colisHistory.length; index++) {
                if (etatColiKO.includes(response.data.colisHistory[index].fk_etat))
                    if (!data) {
                        data = {
                            fk_tiersSource: response.data.fk_tiersSource,
                            fk_tiersDestination: response.data.fk_tiersDestination,
                            numero: `${response.data.numero_document}*`,
                            colis: [
                                {
                                    numero: response.data.colisHistory[index].numero_colis,
                                    poids: response.data.colisHistory[index].poids,
                                    id_colis: response.data.colisHistory[index].id_colis,
                                },
                            ],
                        };
                    } else {
                        data.colis.push({
                            numero: response.data.colisHistory[index].numero_colis,
                            poids: response.data.colisHistory[index].poids,
                            id_colis: response.data.colisHistory[index].id_colis,
                        });
                    }
            }
            setRenvoisColisData(data);
        }
        if (response) setDocument(response.data);
    };
    const renvoiColis = async () => {
        const response = await POST(API.DOCUMENT_RENVOI, renvoisColisData);
        dispatch({ status: response.status, message: response.message });
        if (!status) return;
        GetDocument();
    };

    useEffect(() => {
        GetDocument();
    }, []);

    const onSubmit = async (data: Options) => {
        const body = {
            fk_etat: +data.value,
            fk_document: +id,
        };
        const { status, message } = await POST(API.DOCUMENTS_STATUS, body);
        if (!status) return dispatch({ status, message });
        dispatch({ message, status });
        GetDocument();
        setShowSelect(false);
    };

    const columnsDocuments: ColumnsType<ModelDocumentHistory> = [
        {
            title: 'Date',
            render: (text, props) => dayjs(props.lastHistoryEtat).format('DD/MM/YYYY HH:mm'),
            defaultSortOrder: 'descend',
        },
        {
            title: 'Etat',
            render: (text, props) => (
                <span
                    className={classNames('text-sm p-1', {
                        'text-green-500': props.couleur === 'success',
                        'text-purple-600': props.couleur === 'secondary',
                        'text-blue-500': props.couleur === 'info',
                        'text-orange-500': props.couleur === 'warning',
                        'text-red-500': props.couleur === 'error',
                    })}
                >
                    {props.Libelle_fk_etat}
                </span>
            ),
        },
        {
            title: 'Livraison',
            render: (text, props) => (
                <span className="flex justify-between">
                    <Link to={`${Routes.DELIVERY}/${props.fk_livraison}`} className="w-full">
                        <p className="text-sm text-purple-900 hover:underline">{props.fk_livraison}</p>
                    </Link>
                </span>
            ),
        },
        {
            title: 'Livreur',
            render: (text, props) => <span className="flex justify-between">{props.concatNomPrenomLivreur}</span>,
        },
    ];
    const columnsColis: ColumnsType<ModelDocumentColisHistory> = [
        {
            title: 'Colis',
            render: (text, props) => (
                <span className="flex justify-between">
                    <Link to={`${Routes.PACKAGES}/${props.id_colis}`} className="w-full">
                        <p className="text-sm text-purple-900 hover:underline">{props.numero_colis}</p>
                    </Link>
                </span>
            ),
        },
        {
            title: 'Etat',
            render: (text, props) => (
                <span
                    className={classNames('text-sm p-1', {
                        'text-green-500': props.couleur === 'success',
                        'text-purple-600': props.couleur === 'secondary',
                        'text-blue-500': props.couleur === 'info',
                        'text-orange-500': props.couleur === 'warning',
                        'text-red-500': props.couleur === 'error',
                    })}
                >
                    {props.Libelle_fk_etat}
                </span>
            ),
        },
    ];
    return (
        <section className="container mx-auto my-5 px-2 grid grid-cols-1 lg:grid-cols-6 gap-5">
            <Card className="lg:col-span-2 lg:col-start-1" icon={FaFile} info={'Ajouté le ' + document?.dateCreation}>
                {'Document ' + document?.numero_document}
            </Card>
            <Card className="lg:col-span-2 lg:col-start-3" icon={FaWarehouse} info={'En provenance de'}>
                <div className="flex items-center justify-between">
                    <Link to={Routes.COMPANY + '/' + document?.fk_tiersSource} className="text-purple-900 hover:underline">
                        {document?.raisonSocialeTiersSource}
                    </Link>
                </div>
                {document?.fk_fichier && (
                    <div className="flex items-center">
                        <FileTextOutlined className="mr-1" />
                        <Link to={`${Routes.FILES_HISTORY}/${document?.fk_fichier}`} target="_blank">
                            <p className="text-sm text-left text-purple-900 hover:underline">{document?.filename}</p>
                        </Link>
                        <Link to={`${Routes.FILES_HISTORYBRUT_ID}?${document?.fk_fichier}`} target="_blank">
                            <SettingOutlined className="!text-green-600 ml-2 mt-0" />
                        </Link>
                    </div>
                )}
            </Card>
            <Card className="lg:col-span-2 lg:col-start-5 " icon={FaFlagCheckered} info={'À destination de'}>
                <Link to={Routes.COMPANY + '/' + document?.fk_tiersDestination} className="text-purple-900 hover:underline">
                    {document?.raisonSocialeTiersDestination}
                </Link>
            </Card>
            <div className="lg:col-start-1 lg:col-span-4 lg:row-start-2 row-span-6 flex flex-col">
                <p>Historique de document</p>
                {document?.documentHistory && <Table columns={columnsDocuments} dataSource={document?.documentHistory} pagination={false} />}
                {renvoisColisData && (
                    <Button type="button" className="mt-5" onClick={() => renvoiColis()} bold>
                        <p>Renvoyer les colis KO</p>
                    </Button>
                )}
            </div>
            <div className="lg:col-start-5 lg:col-span-2 lg:row-start-2 flex flex-col">
                <p>Liste des colis</p>
                {document?.colisHistory && <Table columns={columnsColis} dataSource={document?.colisHistory} pagination={false} />}
            </div>
            <div className="border border-purple-400 py-5 lg:col-start-5 lg:col-span-2 lg:row-start-3 flex items-center p-5">
                <span className="flex flex-col">
                    <p>{document?.raisonSocialeTiersDestination}</p>
                    <p className="text-sm text-gray-600">{document?.concatTiersDestinationAdress}</p>
                </span>
            </div>
            <div className="py-5 lg:row-start-4 lg:col-start-5 lg:col-span-2">
                <Notification message={state.message} status={state.status} />
                {showSelect && (
                    <>
                        <Select name="etat" option={statusOption} onChange={(e: Options) => setStatus(e)} className="py-2" isMulti={false} />
                        <Button type="submit" className="w-full flex items-center justify-center" variant="secondary" onClick={() => onSubmit(status)} bold>
                            Appliquer le changement ainsi qu&apos;aux colis
                        </Button>
                    </>
                )}
                <Button type="button" className="w-full flex items-center justify-center mt-5" onClick={() => setShowSelect(!showSelect)} bold>
                    {showSelect ? <p>Ne pas changer l&apos;état</p> : <p>Changer l&apos;état</p>}
                </Button>
            </div>
        </section>
    );
};

export default Document;
