import formatSelect from 'models/OptionsBuilder';
import API from 'constants/API';
import { GET } from 'utils/Fetch';

export async function GetlisteEtat(type?: string, selectNull?: boolean) {
    const response = await GET(`${API.OPTIONS_STATUS}`);
    let filtreCaisse = response.data;
    if (type) filtreCaisse = response.data.filter((e: any) => e.ForType.includes(type));
    if (selectNull) return formatSelect(filtreCaisse, 'id', 'Libelle', 'Pas de selection');
    return formatSelect(filtreCaisse, 'id', 'Libelle');
}
