import dayjs from 'dayjs';
import 'dayjs/locale/fr';
import customParseFormat from 'dayjs/plugin/customParseFormat';
dayjs.extend(customParseFormat);
import { utils, writeFileXLSX } from 'xlsx';

type Definition = {
    header: string;
    data?: string;
    type?: 'date' | 'heure' | 'dateHeure' | 'string' | 'entier' | 'decimal' | 'monetaire' | 'pourcentage';
    footer?: 'total' | 'moyenne';
    fonction?: (row: any) => string;
};

export type ExcelHeaderDefinitions = Definition[];

export function indiceColonne(n: number) {
    let colonne = '';
    while (n > 26) {
        const r = n % 26;
        n = Math.trunc(n / 26);
        colonne = String.fromCharCode(64 + r) + colonne;
    }
    return String.fromCharCode(64 + n) + colonne;
}

const typeCell = {
    date: 's',
    heure: 's',
    dateHeure: 's',
    string: 's',
    entier: 'n',
    decimal: 'n',
    monetaire: 'n',
    pourcentage: 'n',
};

export const Exportxlsx = (
    JSdata: any[],
    nomFichier: string,
    sheetName: string,
    definition: ExcelHeaderDefinitions,
) => {
    const ws = utils.json_to_sheet([]);
    ws['!cols'] = [];

    for (let index = 0; index < definition.length; index++) {
        let tailleCell = 0;

        //  gestion des datas
        for (let i = 0; i < JSdata.length; i++) {
            let value = definition[index].data
                ? JSdata[i][definition[index].data as keyof typeof JSdata]
                : definition[index]?.fonction?.(JSdata[i]);
            if (!value) {
                switch (definition[index].type) {
                    case 'entier':
                    case 'pourcentage':
                    case 'monetaire':
                        value = '0';
                        break;

                    default:
                        break;
                }
            }
            if (value) {
                // format date
                if (definition[index].type === 'entier') value = +value.toString();
                if (definition[index].type === 'date') value = dayjs(value).format('DD/MM/YYYY');
                // if (definition[index].type === 'heure') value = dayjs(value, 'HH:mm:ss').format('HH:mm:ss');
                if (definition[index].type === 'dateHeure') value = dayjs(value).format('DD/MM/YYYY HH:mm:ss');

                const cell: any = {
                    t: typeCell[definition[index].type as keyof typeof typeCell],
                    v: value,
                };

                if (definition[index].type === 'date') cell.z = 'dd/mm/yyyy';
                if (definition[index].type === 'heure') cell.z = 'hh:mm';
                if (definition[index].type === 'dateHeure') cell.z = 'dd/mm/yyyy hh:mm:ss';
                if (definition[index].type === 'monetaire') cell.z = '#,##0.00\\ "€"';
                if (definition[index].type === 'pourcentage') cell.z = '#,##0.00\\ "%"';

                utils.sheet_add_aoa(ws, [[cell]], { origin: `${indiceColonne(index + 1)}${i + 2}` });

                // taille de la cellule
                if (tailleCell - 5 < value.toString().length) tailleCell = value.toString().length + 5;
            }
        }
        // gestion du header
        utils.sheet_add_aoa(
            ws,
            [
                [
                    {
                        t: 's',
                        v: definition[index].header,
                    },
                ],
            ],
            { origin: `${indiceColonne(index + 1)}1` },
        );
        // gestion des totaux
        if (definition[index].footer && JSdata.length > 1) {
            let fonction;
            if (definition[index].footer === 'total')
                fonction = ` SUM(${indiceColonne(index + 1)}2:${indiceColonne(index + 1)}${JSdata.length + 1})`;
            if (definition[index].footer === 'moyenne')
                fonction = ` AVERAGE(${indiceColonne(index + 1)}2:${indiceColonne(index + 1)}${JSdata.length + 1})`;
            const cell: any = {
                t: typeCell[definition[index].type as keyof typeof typeCell],
                f: fonction,
            };

            if (definition[index].type === 'monetaire')
                //  format data
                cell.z = '#,##0.00\\ "€"';
            if (definition[index].type === 'pourcentage') cell.z = '#,##0.00\\ "%"';
            utils.sheet_add_aoa(ws, [[cell]], { origin: `${indiceColonne(index + 1)}${JSdata.length + 2}` });
        }
        if (tailleCell - 5 < definition[index].header.toString().length)
            tailleCell = definition[index].header.length + 5;
        ws['!cols'].push({ width: tailleCell });
    }

    const wb = utils.book_new();
    utils.book_append_sheet(wb, ws, sheetName);
    writeFileXLSX(wb, nomFichier);

    /*
    En utilisant l'objet 'définition'
    créer un tableau de tableau
        aoa = []
    Le première ligne est le tableau de header
        aoa.push(tableauHeader)
    les lignes suivantes sont les données JSON ordonnées en fonction du header, un tableau par ligne
        foreach(JSdata)
            définir le type de chaque cellule
            aoa.push(tableauLigne)
            attention, en fonction du type de la colonne il faut peut-être transformer la data (genre pour float remplacer le point par une virgule)

    créer le workbook
        const workbook = utils.book_new();
    transférer les données du tableau dans une feuille
        const worksheet = utils.aoa_to_sheet(aoa);
    optionnel : parcourir les cellules du worksheet pour

    ajouter le worksheet au workbook
        utils.book_append_sheet(workbook, worksheet, 'Ventes');
    enregistrer le workbook
        writeFileXLSX(workbook, nomFichier);
    */

    return;
};
