import React, { useState, useEffect, Fragment, useContext } from 'react';
import { Link } from 'react-router-dom';
import { Chart as ChartJS, ArcElement, Tooltip, Legend } from 'chart.js';
import { Pie } from 'react-chartjs-2';

import { ColumnsType } from 'antd/lib/table';
import { Modal, Table } from 'antd';

import ButtonCustom from 'components/atoms/Buttons';

import classNames from 'classnames';
import API from 'constants/API';
import { GET, POST } from 'utils/Fetch';
import Routes from 'constants/Routes';
import FormatDate from 'utils/FormatDate';
import { NotificationContext } from 'contexts/Notifications';
import { useAuthenticateContext } from 'contexts/Authentication';
import { ModelHistoryEtatHome } from 'models/ModelTypeData';

import dayjs from 'dayjs';
import GrafStacked from '../../components/organisms/GrafStacked';

ChartJS.register(ArcElement, Tooltip, Legend);

const HomePage = () => {
    const { authentication } = useAuthenticateContext();
    const { dispatch } = useContext(NotificationContext);
    const [open, setOpen] = useState<{ open: boolean; type?: 'graf' | 'camembert' }>({ open: false, type: 'graf' });
    const [loading, setLoading] = useState<boolean>(false);
    const [historyEtatList, setHistoryEtatList] = useState<ModelHistoryEtatHome[]>([]);
    const [dataGraf, setDataGraf] = useState<
        {
            label: string;
            data: number[];
            backgroundColor: string;
        }[]
    >([]);
    const [labelGraf, setLabelgraf] = useState<string[]>([]);
    const [waitingFile, setwaitingFile] = useState<string[]>([]);
    const [statCam, setStatCam] = useState<{
        label: string[];
        data: number[];
        backgroundColor: string[];
    }>({
        label: [],
        data: [],
        backgroundColor: [],
    });

    function getRandomColor() {
        const letters = '0123456789ABCDEF';
        let color = '#';
        for (let i = 0; i < 6; i++) {
            color += letters[Math.floor(Math.random() * 16)];
        }
        return color;
    }

    const GetHistory = async () => {
        const response = await GET(API.STATS_DOCUMENTS_COLIS);
        if (!response.status) return dispatch({ status: response.status, message: response.message });
        setHistoryEtatList(response.data);
    };

    const GetPackageStats = async () => {
        const { status, message, ...response } = await GET(
            `${API.PACKAGE_STATS_PIE}?startDate=${new Date().getFullYear()}-01-01&endDate=${FormatDate(new Date())}`,
        );
        if (!status) dispatch({ status, message });
        const modelStat: any = {
            label: [],
            data: [],
            backgroundColor: [],
        };
        for (let index = 0; index < response.data.length; index++) {
            modelStat.label.push(response.data[index].RaisonSociale);
            modelStat.data.push(response.data[index].nbColis);
            modelStat.backgroundColor.push(getRandomColor());
        }
        setStatCam(modelStat);
    };
    const GetAllTiersStats = async () => {
        const tiers = await GET(API.OPTIONS_COMPANIES_SOURCE);
        const response = await GET(
            `${API.PACKAGE_STATS_COLIS_SOURCE}?debutPeriode=${dayjs().subtract(1, 'years').format('YYYY-MM-DD')}&finPeriode=${dayjs().format('YYYY-MM-DD')}`,
        );
        const moisTitre = ['Janvier', 'Février', 'Mars', 'Avril', 'Mai', 'Juin', 'Juilliet', 'Aout', 'Septembre', 'Octobre', 'Novembre', 'Descembre'];
        const datasets: any[] = [];
        const labelOrdonner: any[] = [];
        for (let index = 0; index < tiers.data.length; index++) {
            let indexArray = datasets.findIndex((e: any) => e.label === tiers.data[index].RaisonSociale);
            if (indexArray === -1) {
                datasets.push({
                    label: tiers.data[index].RaisonSociale,
                    data: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
                    backgroundColor: getRandomColor(),
                });
                indexArray = datasets.length - 1;
            }
            for (let i = 0; i < response.data.length; i++) {
                const id = parseInt(response.data[i].mois) - 1;
                const labelMois = `${moisTitre[id]} ${response.data[i].annee}`;
                const indexDatasets = labelOrdonner.findIndex((e) => e === labelMois);
                if (indexDatasets === -1) labelOrdonner.push(labelMois);

                if (datasets[indexArray].label === response.data[i].RaisonSociale) datasets[indexArray].data[indexDatasets] = response.data[i].nbColis;
            }
        }
        setLabelgraf(labelOrdonner);
        setDataGraf(datasets);
    };
    useEffect(() => {
        GetHistory();
        GetPackageStats();
        GetWaitingFile();
        GetAllTiersStats();
    }, []);

    const SendToDelivery = async (idCentreLogistique: number) => {
        setLoading(true);
        const { status, message } = await POST(`${API.MISE_EN_LIVRAISONS}`, { idCentreLogistique: idCentreLogistique });
        setLoading(false);
        return dispatch({ status, message });
    };

    const GetWaitingFile = async () => {
        const { status, message, data } = await GET(API.WAITING_FILES);
        if (!status) return dispatch({ status, message });
        if (data !== '') setwaitingFile(data.data.split(','));
    };

    const StartFileProcesss = async () => {
        const { status, message } = await POST(API.START_FILE_PROCESS);
        if (!status) return dispatch({ status, message });
        setwaitingFile([]);
    };
    const columnsTable: ColumnsType<ModelHistoryEtatHome> = [
        {
            title: 'État colis',
            render: (text, props) => (
                <>
                    {props.ForType.split(',').includes('colis') && (
                        <Link to={`${Routes.PACKAGES}?etat=${props.idEtat}`}>
                            <span className="text-sm w-5/6 text-purple-900 hover:underline">{props.libelleEtat}</span>
                        </Link>
                    )}
                </>
            ),
        },
        {
            title: '',
            render: (text, props) => <span>{props.ForType.split(',').includes('colis') ? props.counterColis : ''}</span>,
        },
        {
            title: 'État document',
            render: (text, props) => (
                <>
                    {props.ForType.split(',').includes('document') && (
                        <Link to={`${Routes.DOCUMENTS}?etat=${props.idEtat}`}>
                            <span className="text-sm w-5/6 text-purple-900 hover:underline">{props.libelleEtat}</span>
                        </Link>
                    )}
                </>
            ),
        },
        {
            title: '',
            render: (text, props) => <span>{props.ForType.split(',').includes('document') ? props.counterDocument : ''}</span>,
        },
        {
            title: 'État livraison',
            render: (text, props) => (
                <>
                    {props.ForType.split(',').includes('livraison') && (
                        <Link to={`${Routes.DELIVERY}?etat=${props.idEtat}`}>
                            <span className="text-sm w-5/6 text-purple-900 hover:underline">{props.libelleEtat}</span>
                        </Link>
                    )}
                </>
            ),
        },
        {
            title: '',
            render: (text, props) => <span>{props.ForType.split(',').includes('livraison') ? props.counterLivraison : ''}</span>,
        },
    ];
    const data = {
        labels: statCam.label,
        datasets: [
            {
                data: statCam.data,
                backgroundColor: statCam.backgroundColor,
                borderColor: statCam.backgroundColor,
                borderWidth: 1,
            },
        ],
    };
    return (
        <>
            <Modal width={'90%'} open={open.open} onCancel={() => setOpen({ open: false })} footer={[]}>
                <GrafStacked label={labelGraf} datasets={dataGraf} />
            </Modal>
            <section className="relative flex flex-col items-center">
                <div className="flex">
                    {authentication.centreLogistique.map((row: any, i) => (
                        <ButtonCustom key={i} variant="primary" className="mt-5" onClick={() => SendToDelivery(row.idCL)}>
                            {loading && <svg className="animate-spin h-5 w-5 mr-3 rounded-full border-r-2 border-t-2 border-white z-50" viewBox="0 0 24 24" />}
                            Mise en Livraison ( {row.libelleCL} )
                        </ButtonCustom>
                    ))}
                </div>
                <div className="container flex m-auto flex-col items-center lg:flex-row lg:items-start">
                    <div className="w-full mx-5 lg:my-5 pt-2 lg:w-1/2 shadow-md bg-white">
                        <div className="flex justify-between items-center border bg-purple-700  border-purple-700 px-2 mb-1 rounded-t-md">
                            <h2 className="text-2xl text-gray-100 my-2">Stat colis/Documents/Livraison</h2>
                        </div>
                        <Table
                            rowClassName={(record) =>
                                classNames('text-purple-800', {
                                    'bg-purple-200': record.couleur === 'secondary',
                                    'bg-blue-200': record.couleur === 'info',
                                    'bg-green-200': record.couleur === 'success',
                                    'bg-red-200': record.couleur === 'error',
                                })
                            }
                            columns={columnsTable}
                            dataSource={historyEtatList}
                            pagination={false}
                        />
                    </div>
                    <div className="flex flex-col w-full lg:w-1/2">
                        {waitingFile.length !== 0 && (
                            <div className="flex flex-col w-full lg:mx-2 lg:my-5 py-2">
                                <div className="flex justify-between items-center border bg-purple-700  border-purple-700 px-2 mb-1 rounded-t-md">
                                    <h2 className="text-2xl text-gray-100 my-2">Fichier(s) en attente</h2>
                                    <ButtonCustom variant="primary" onClick={() => StartFileProcesss()} bold>
                                        Traiter les fichiers
                                    </ButtonCustom>
                                </div>
                                {waitingFile.map((item) => (
                                    <p key={item}>{item}</p>
                                ))}
                            </div>
                        )}
                        <div>
                            <div className="flex flex-col w-full lg:mx-2 lg:my-5 py-2">
                                <div className="flex justify-between items-center border bg-purple-700  border-purple-700 px-2 mb-1 rounded-t-md">
                                    <h2 className="text-2xl text-gray-100 my-2">Statistique</h2>
                                    <ButtonCustom variant="primary" onClick={() => setOpen({ open: true, type: 'graf' })} bold>
                                        Histogramme
                                    </ButtonCustom>
                                </div>
                                <div className="border shadow-md p-2 bg-white h-[550px]">
                                    <Pie data={data} />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    );
};

export default HomePage;
