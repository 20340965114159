import React, { FunctionComponent } from 'react';
import classNames from 'classnames';

interface Props {
    couleur: null | string;
    Libelle: null | string;
}

const SpanLabel: FunctionComponent<Props> = ({ couleur, Libelle }) => {
    return (
        <span
            className={classNames('text-sm p-1', {
                'text-green-500': couleur === 'success',
                'text-purple-600': couleur === 'secondary',
                'text-blue-500': couleur === 'info',
                'text-orange-500': couleur === 'warning',
                'text-red-500': couleur === 'error',
            })}
        >
            {Libelle}
        </span>
    );
};

export default SpanLabel;
