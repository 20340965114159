import React, { Fragment, useContext, useEffect, useState } from 'react';
import { Link } from 'react-router-dom';

import { ColumnsType } from 'antd/lib/table';
import { Table, Button } from 'antd';

import { FaTrash, FaRegEdit } from 'react-icons/fa';

import { NotificationContext } from 'contexts/Notifications';
import ConfirmDelete from '../ConfirmDelete';
import CloseForm from '../CloseForm';

import { DELETE, GET } from 'utils/Fetch';

import API from 'constants/API';
import Routes from 'constants/Routes';
import { Close, EmptyCloseData } from 'models/CompanyBuilder/CompanyClosure';
import { ModelListFermetureTiers } from 'models/ModelTypeData';

const ClosureList = () => {
    const { dispatch } = useContext(NotificationContext);
    const [ClosureList, setClosureList] = useState<ModelListFermetureTiers[]>([]);
    const [ClosureModal, setClosureModal] = useState<boolean>(false);
    const [comfirmDelete, setConfirmDelete] = useState<boolean>(false);
    const [companyId, setCompanyId] = useState<string>('');
    const [companyClosure, setCompanyClosure] = useState<Close>(EmptyCloseData);

    const GetCloseList = async () => {
        const { status, message, ...response } = await GET(API.COMPANY_CLOSED_LIST);
        if (!status) return dispatch({ status, message });
        setClosureList(response.data);
    };

    useEffect(() => {
        if (!ClosureModal && !comfirmDelete) GetCloseList();
    }, [ClosureModal, comfirmDelete]);

    const DeleteClosure = async (id: string) => {
        const { status, message } = await DELETE(`${API.COMPANY_CLOSURE}/${id}`);
        dispatch({ status, message });
        setConfirmDelete(false);
    };

    const columnsTable: ColumnsType<ModelListFermetureTiers> = [
        {
            title: 'Pharmacie',
            render: (text, props) => (
                <>
                    <span className="flex justify-between">
                        <Link to={`${Routes.COMPANY}/${props.id}`} className="w-full">
                            <p className="text-sm text-purple-900 hover:underline">
                                {props.RaisonSociale}
                                <span className="text-gray-500 italic text-xs ml-1">({props.Identifiant})</span>
                            </p>
                        </Link>
                        <button
                            type="button"
                            className="outlined-none text-red-600  px-1"
                            onClick={() => {
                                setConfirmDelete(true);
                                setCompanyId(`${props.fk_tiers}`);
                            }}
                        >
                            <FaTrash />
                        </button>
                        <button
                            type="button"
                            className="outlined-none text-purple-600 px-1 text-lg"
                            onClick={() => {
                                setClosureModal(true);
                                setCompanyClosure((prevState) => ({
                                    ...prevState,
                                    endDate: new Date(props.end_date),
                                    startDate: new Date(props.start_date),
                                }));
                            }}
                        >
                            <FaRegEdit />
                        </button>
                    </span>
                    <span className="text-gray-400 italic text-xs">{props.concatAdresse}</span>
                </>
            ),
            sorter: (a, b) => a.RaisonSociale.localeCompare(b.RaisonSociale),
            defaultSortOrder: 'ascend',
        },
        {
            title: 'Début',
            render: (text, props) => <span>{props.start_date}</span>,
        },
        {
            title: 'Fin',
            render: (text, props) => <span>{props.end_date}</span>,
        },
        {
            title: 'Commentaire',
            render: (text, props) => <span>{props.commentaire}</span>,
        },
    ];

    return (
        <section className="container mx-auto p-5">
            <Button
                className="!border-purple-700 !bg-purple-700 !font-bold text-white mr-5 mb-5"
                onClick={() => setClosureModal(true)}
            >
                Créer une fermeture
            </Button>
            <Table
                columns={columnsTable}
                dataSource={ClosureList}
                pagination={{
                    showTotal: (total: any, range: any) => `${range[0]}-${range[1]} / ${total} `,
                    position: ['topRight', 'bottomRight'],
                    total: ClosureList?.length,
                    defaultCurrent: 1,
                    defaultPageSize: 20,
                }}
            />
            <CloseForm
                open={ClosureModal}
                setOpen={setClosureModal}
                closure={companyClosure}
                setClosure={setCompanyClosure}
            />
            <ConfirmDelete
                open={comfirmDelete}
                setOpen={setConfirmDelete}
                id={companyId}
                Delete={DeleteClosure}
                label="closure"
            />
        </section>
    );
};

export default ClosureList;
