import React, { FunctionComponent } from 'react';
import { Chart as ChartJS, CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend } from 'chart.js';
import { Bar } from 'react-chartjs-2';

ChartJS.register(CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend);

interface GrafProps {
    label: string[];
    datasets: {
        label: string;
        data: any[];
        backgroundColor: string;
    }[];
    className?: string;
}

export const options = {
    plugins: {
        title: {
            display: true,
            text: 'Nombre de colis au mois.',
        },
    },
    responsive: true,
    scales: {
        x: {
            stacked: true,
        },
        y: {
            stacked: true,
        },
    },
};

const GrafStacked: FunctionComponent<GrafProps> = ({ label, datasets, className }) => {
    const data = {
        labels: label,
        datasets: datasets,
    };
    return (
        <div className={className}>
            <Bar options={options} data={data} />
        </div>
    );
};
export default GrafStacked;
