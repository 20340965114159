import React, { FunctionComponent, useContext } from 'react';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { NotificationContext } from 'contexts/Notifications';

interface Notification {
    message: string;
    status: boolean;
    autoClose?: false | number;
}

const Notification: FunctionComponent<Notification> = ({ message, status, autoClose }) => {
    const customId = 'custom-id-yes' + message;
    const { dispatch } = useContext(NotificationContext);
    switch (status) {
        case false:
            toast.error(message, {
                toastId: customId,
                onClose: () => dispatch({ message: null }),
            });
            break;
        case true:
            toast.success(message, {
                toastId: customId,
                onClose: () => dispatch({ message: null }),
            });
            break;
        default:
            break;
    }
    let autoCloseTime: false | number = 5000;
    if (autoClose) autoCloseTime = autoClose;

    return (
        <ToastContainer
            position="top-center"
            autoClose={autoCloseTime}
            hideProgressBar={false}
            newestOnTop={false}
            closeOnClick
        />
    );
};

export default Notification;
