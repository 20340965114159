import React, { useContext, useEffect, useState } from 'react';
import { Link } from 'react-router-dom';

import { ColumnsType } from 'antd/lib/table';
import { Table, Select, Button } from 'antd';

import { FaUserPlus, FaTrash } from 'react-icons/fa';

import ConfirmDelete from './ConfirmDelete';
import Icon from 'components/atoms/Icon';
import Notification from 'components/atoms/Notifications';
import RechercheTable from 'components/organisms/rechercheTable';
import ExportSelect from 'components/organisms/ExportSelect';
import CloseForm from './CloseForm';

import API from 'constants/API';
import Routes from 'constants/Routes';
import { ModelListTiers } from 'models/ModelTypeData';
import { CompaniesListBuilder } from 'models/CompanyBuilder/CompaniesList';

import { DELETE, GET } from 'utils/Fetch';
import { NotificationContext } from 'contexts/Notifications';
import { Options } from 'utils/CreateQueries';
import { ExcelHeaderDefinitions } from 'utils/ExportXLS';

const definitionsTableau: ExcelHeaderDefinitions = [
    { header: 'Identifiant', data: 'Identifiant', type: 'string' },
    { header: 'Raison sociale', data: 'RaisonSociale', type: 'string' },
    { header: 'Adresse', data: 'concatAdresse', type: 'string' },
    { header: 'Numero clé', data: 'numeroCle', type: 'string' },
    { header: 'Etat clé', data: 'libelleEtat', type: 'string' },
    { header: 'Horaire', data: 'openingHours', type: 'string' },
    { header: 'type', data: 'libelle_fk_TiersType', type: 'string' },
];

const CompaniesListPage = () => {
    const { state, dispatch } = useContext(NotificationContext);
    const [copieData, setCopieData] = useState<ModelListTiers[]>();
    const [tiersList, setTiersList] = useState<ModelListTiers[]>();
    const [tiersTypesList, setTiersTypesList] = useState<Options[]>(CompaniesListBuilder());
    const [openModal, setOpenModal] = useState<boolean>(false);
    const [closeForm, setCloseForm] = useState<boolean>(false);
    const [tiersId, setTiersId] = useState<string>('');

    const GetTiersList = async (type?: string) => {
        let query = '';
        if (type) query = `?type=${type}`;
        const { message, status, ...response } = await GET(API.TIERS_LIST + query);
        if (!status) return dispatch({ message, status });
        setTiersList(response.data);
        setCopieData(response.data);
    };

    const GetTiersTypeList = async () => {
        const { message, status, ...response } = await GET(API.OPTIONS_COMPANIES_TYPE);
        if (!status) return dispatch({ message, status });
        const select = [{ label: 'Pas de selection', value: '' }];
        for (let index = 0; index < response.data.length; index++) {
            select.push({ label: response.data[index].Libelle, value: response.data[index].id });
        }
        setTiersTypesList(select);
    };

    const DeleteTiers = async (tiersId: string) => {
        const { status, message } = await DELETE(`${API.TIER}/${tiersId}`);
        dispatch({ status, message });
        setOpenModal(!status);
    };

    const columnsTable: ColumnsType<ModelListTiers> = [
        {
            title: 'Identifiant',
            sorter: (a, b) => a.Identifiant.localeCompare(b.Identifiant),
            render: (text, props) => (
                <span className="flex justify-between">
                    <Link to={`${Routes.COMPANY}/${props.id}`} className="w-full">
                        <p className="text-sm text-purple-900 hover:underline">{props.Identifiant}</p>
                    </Link>
                    <button
                        type="button"
                        className="outlined-none text-red-600"
                        onClick={() => {
                            setOpenModal(true);
                            setTiersId(`${props.id}`);
                        }}
                    >
                        <FaTrash />
                    </button>
                </span>
            ),
        },
        {
            title: 'Raison Social',
            sorter: (a, b) => a.RaisonSociale.localeCompare(b.RaisonSociale),
            render: (text, props) => (
                <Link style={{ color: '#800080' }} to={`${Routes.COMPANY}/${props.id}`}>
                    {props.RaisonSociale}
                </Link>
            ),
            defaultSortOrder: 'ascend',
        },
        {
            title: 'Ville',
            render: (text, props) => <span>{props.concatAdresse}</span>,
        },
        {
            title: 'Clé',
            render: (text, props) => <span>{props.numeroCle}</span>,
        },
        {
            title: 'Horaire',
            render: (text, props) => (
                <div>
                    {props.openingHours &&
                        props.openingHours.split(';').map((row: any, i: number) => (
                            <span key={i}>
                                {row}
                                <br />
                            </span>
                        ))}
                </div>
            ),
        },
        {
            title: 'Type',
            render: (text, props) => <span>{props.libelle_fk_TiersType}</span>,
        },
    ];

    useEffect(() => {
        GetTiersTypeList();
        GetTiersList();
    }, []);

    return (
        <section className="mx-10 my-5">
            <Notification message={state.message} status={state.status} />
            <div className="flex justify-between">
                <div className="w-[20%] flex flex-col">
                    <span className="mb-1">Type de tiers</span>
                    <Select onChange={(value) => GetTiersList(value)} options={tiersTypesList} />
                </div>
                <div>
                    <div className="flex items-center justify-end mb-2">
                        <Button className="!border-purple-700 !bg-purple-700 !font-bold text-white mr-5" onClick={() => setCloseForm(true)}>
                            Créer fermeture
                        </Button>
                        {/* <Button onClick={() => ExportCSV(tiersListold)}>
                            <Icon Icon={FaFileCsv} className="w-5" />
                            Exporter les tiers
                        </Button> */}
                        <ExportSelect
                            className="!font-bold "
                            data={tiersList}
                            nomComplet="tiers_Liste.xlsx"
                            nomFichier="tiers_Liste"
                            definitions={definitionsTableau}
                        />
                        <Link to={`${Routes.COMPANY}/0`}>
                            <Button className="!border-purple-700 !bg-purple-700 !font-bold text-white">
                                <Icon Icon={FaUserPlus} className="w-5" />
                                Ajouter un tiers
                            </Button>
                        </Link>
                    </div>
                    <RechercheTable data={copieData} dataFiltre={(data: any) => setTiersList(data)} />
                </div>
            </div>
            <Table
                columns={columnsTable}
                dataSource={tiersList}
                pagination={{
                    showTotal: (total: any, range: any) => `${range[0]}-${range[1]} / ${total} `,
                    position: ['topRight', 'bottomRight'],
                    total: tiersList?.length,
                    defaultCurrent: 1,
                    defaultPageSize: 20,
                }}
            />
            <ConfirmDelete open={openModal} setOpen={setOpenModal} id={tiersId} Delete={DeleteTiers} label="company" />
            <CloseForm open={closeForm} setOpen={setCloseForm} />
        </section>
    );
};

export default CompaniesListPage;
