import React, { FunctionComponent, useContext, useEffect, useState } from 'react';

import Button from 'components/atoms/Buttons';

import TextArea from 'components/atoms/Form/textArea';
import DateInput from 'components/molecules/Inputs/DatePicker';
import { Controller, useForm } from 'react-hook-form';
import { GET, PATCH, POST } from 'utils/Fetch';
import API from 'constants/API';
import { NotificationContext } from 'contexts/Notifications';
import { FormatOptionList } from 'models/OptionsBuilder';
import { Close, EmptyCloseData, ReFormatClose } from 'models/CompanyBuilder/CompanyClosure';
import DropdownCombobox from 'components/atoms/Form/downShift';

interface ModalProps {
    open: boolean;
    setOpen: React.Dispatch<React.SetStateAction<boolean>>;
    closure?: Close;
    setClosure?: React.Dispatch<React.SetStateAction<Close>>;
    selectDisabled?: boolean;
}

const CloseForm: FunctionComponent<ModalProps> = ({
    open = false,
    setOpen,
    closure,
    setClosure = () => {},
    selectDisabled,
}) => {
    const { dispatch } = useContext(NotificationContext);

    const [companies, setCompanies] = useState<{ label: string; value: string }[]>([{ label: '', value: '' }]);
    const [loading, setLoading] = useState<boolean>(true);

    const { register, errors, handleSubmit, control, reset, setValue, watch } = useForm<Close>({
        defaultValues: closure,
        mode: 'onSubmit',
    });

    const GetTiersList = async () => {
        const { status, ...response } = await GET(API.TIERS_LIST);

        if (!status) return;
        setCompanies(FormatOptionList(response, 'Pharmacie'));
        SetValue();
        setLoading(false);
    };

    useEffect(() => {
        if (!open) return;
        GetTiersList();
        document.addEventListener('keydown', function (event) {
            if (event.key === 'Escape') {
                setOpen(false);
            }
        });
    }, [open]);

    const SetValue = () => {
        if (closure) {
            setValue('fk_tiers', closure.fk_tiers);
            reset({ ...closure });
        }
    };

    const onSubmit = async (data: Close) => {
        const newClosure = ReFormatClose(data);
        let response;
        if (closure && closure?.id !== null) {
            newClosure.id = closure?.id;
            response = await PATCH(API.COMPANY_CLOSURE, newClosure);
        } else {
            response = await POST(API.COMPANY_CLOSURE, newClosure);
        }

        const { status } = response;
        dispatch({ message: 'La fermeture a bien été enregistrée', status });

        if (!status) return;

        ResetPropsAndCloseModal();
        setOpen(false);
    };

    const ResetPropsAndCloseModal = () => {
        setClosure(EmptyCloseData);
        setOpen(false);
    };

    return (
        <>
            {open && !loading && (
                <>
                    <div className="justify-center items-center flex overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none">
                        <div className="w-auto my-6 mx-auto max-w-lg">
                            <div className="border-0 rounded-lg shadow-lg  flex flex-col w-full bg-white outline-none focus:outline-none">
                                <div className="flex items-start justify-between p-5 border-b border-solid border-blueGray-200 rounded-t">
                                    <h3 className="text-xl font-semibold">Fermeture Pharmacie</h3>
                                </div>
                                <div className="p-6 flex-auto">
                                    <form id="AbsentForm" autoComplete="off" onSubmit={handleSubmit(onSubmit)}>
                                        <Controller
                                            control={control}
                                            error={errors}
                                            name="companyId"
                                            rules={{ required: true }}
                                            defaultValue={closure}
                                            render={({ onChange }) => (
                                                <>
                                                    <DropdownCombobox
                                                        label="Choisir la Pharmacie"
                                                        items={companies}
                                                        onChange={onChange}
                                                        selectDisabled={selectDisabled}
                                                        defaultValue={{
                                                            label: closure ? closure?.RaisonSociale : '',
                                                            value: closure ? `${closure.id}` : '',
                                                        }}
                                                    />
                                                    {errors['id'] && (
                                                        <span className="ml-2 text-red-600">
                                                            {errors['id'] && 'Veuillez sélectionner un tiers'}
                                                        </span>
                                                    )}
                                                </>
                                            )}
                                        />

                                        <div className="flex ">
                                            <Controller
                                                control={control}
                                                error={errors}
                                                name="startDate"
                                                defaultValue={new Date()}
                                                rules={{ required: true }}
                                                render={({ onChange, value }) => (
                                                    <>
                                                        <DateInput
                                                            label="Début"
                                                            selectsStart
                                                            selected={value}
                                                            onChange={onChange}
                                                            startDate={watch('startDate')}
                                                            endDate={watch('endDate')}
                                                        />
                                                        {errors['start_date'] && (
                                                            <span className="ml-2 text-red-600">
                                                                {errors['start_date'] && 'Séléctionner une date'}
                                                            </span>
                                                        )}
                                                    </>
                                                )}
                                            />
                                            <Controller
                                                control={control}
                                                error={errors}
                                                name="endDate"
                                                defaultValue={new Date()}
                                                rules={{ required: true }}
                                                render={({ onChange, value }) => (
                                                    <>
                                                        <DateInput
                                                            label="Fin"
                                                            selectsEnd
                                                            selected={value}
                                                            onChange={onChange}
                                                            startDate={watch('startDate')}
                                                            endDate={watch('endDate')}
                                                            minDate={watch('startDate')}
                                                        />
                                                        {errors['end_date'] && (
                                                            <span className="ml-2 text-red-600 font-bold">
                                                                {errors['end_date'] && 'Séléctionner une date'}
                                                            </span>
                                                        )}
                                                    </>
                                                )}
                                            />
                                        </div>

                                        <TextArea label="Commentaire" name={'comment'} register={register} />
                                    </form>
                                </div>
                                <div className="flex items-center justify-end p-6 border-t border-solid border-blueGray-200 rounded-b">
                                    <Button onClick={() => ResetPropsAndCloseModal()} variant="secondary">
                                        Annuler
                                    </Button>
                                    <Button form="AbsentForm" type="submit" variant="primary">
                                        Valider
                                    </Button>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="opacity-25 fixed inset-0 z-40 bg-black" />
                </>
            )}
        </>
    );
};

export default CloseForm;
