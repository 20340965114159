import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';

import { ColumnsType } from 'antd/lib/table';
import { Table, Select } from 'antd';

import { FaMapMarkedAlt } from 'react-icons/fa';

import Routes from 'constants/Routes';
import API from 'constants/API';

import { GET } from 'utils/Fetch';
import { ExcelHeaderDefinitions } from 'utils/ExportXLS';

import ExportSelect from 'components/organisms/ExportSelect';
import RechercheTable from 'components/organisms/rechercheTable';
import { ModelDestination } from 'models/ModelTypeData';

const definitionsTableau: ExcelHeaderDefinitions = [
    { header: 'Identifiant', data: 'Identifiant', type: 'string' },
    { header: 'Raison sociale', data: 'RaisonSociale', type: 'string' },
    { header: 'Tiers source', data: 'libelleSource', type: 'string' },
    { header: 'Mail', data: 'email', type: 'string' },
    { header: 'Adresse', data: 'adresseComplete', type: 'string' },
    { header: 'Commentaire', data: 'commentaire', type: 'string' },
    { header: 'Horaire', data: 'openingHours', type: 'string' },
];

const DestinataireListPage = () => {
    const [listeDesSources, setListeDesSources] = useState<ModelDestination[]>([]);
    const [listeSources, setListeSources] = useState<any[]>([]);
    const [source, setSource] = useState<string>('all');
    const [copieData, setCopieData] = useState<ModelDestination[]>();

    const GetTiersList = async () => {
        let response = await GET(API.LIST_TIERS_SOURCES);
        if (!response.status) return;
        response.data.unshift({ value: 'all', label: 'Toute les sources' });
        setListeSources(response.data);
        response = await GET(`${API.LIST_TIERS_DES_SOURCES}${source && `?type=source&id=${source}`}`);
        if (!response.status) return;
        setListeDesSources(response.data);
        setCopieData(response.data);
    };

    useEffect(() => {
        GetTiersList();
    }, [source]);

    const columnsTable: ColumnsType<ModelDestination> = [
        {
            title: 'Identifiant',
            sorter: (a, b) => (a.Identifiant && b.Identifiant ? a.Identifiant.localeCompare(b.Identifiant) : 0),
            render: (text, props) => (
                <span className="flex justify-between">
                    <Link to={`${Routes.COMPANY}/${props.tdId}`} className="w-full">
                        <p className="text-sm text-purple-900 hover:underline">{props.Identifiant}</p>
                    </Link>
                </span>
            ),
        },
        {
            title: 'Raison Social',
            render: (text, props) => (
                <Link style={{ color: '#800080' }} to={`${Routes.COMPANY}/${props.tdId}`}>
                    {props.RaisonSociale}
                </Link>
            ),
        },
        {
            title: 'Tiers source',
            render: (text, props) => (
                <Link style={{ color: '#800080' }} to={`${Routes.COMPANY}/${props.fk_tiersSource}`}>
                    {props.tsRaisonSociale}
                </Link>
            ),
        },
        {
            title: 'Mail',
            render: (text, props) => (
                <a style={{ textDecoration: 'none', color: '#04336d' }} href={`mailto:${props.email}`}>
                    {props.email}
                </a>
            ),
        },
        {
            title: 'Adresse',
            render: (text, props) => (
                <span className="flex justify-between">
                    <span>
                        {props.Adresse1}
                        <br /> {props.CodePostal} {props.CodePostal}
                    </span>

                    <a
                        className="text-sm text-purple-900 hover:underline"
                        target="_blank"
                        href={`https://www.google.com/maps/search/?api=1&query=${props.adresseComplete}`}
                        rel="noreferrer"
                    >
                        <FaMapMarkedAlt className="text-lg" />
                    </a>
                </span>
            ),
        },
        {
            title: 'Commentaire',
            render: (text, props) => <span>{props.commentaire}</span>,
        },

        {
            title: 'Horaire',
            render: (text, props) => (
                <div>
                    {props.openingHours &&
                        props.openingHours.split(';').map((row: any, i: number) => (
                            <span key={i}>
                                {row}
                                <br />
                            </span>
                        ))}
                </div>
            ),
        },
    ];

    return (
        <section className="mx-10 my-5">
            <div className="flex items-center justify-between">
                <div className="w-[20%] flex flex-col">
                    <span className="mb-1">Sélection de la source</span>
                    <Select onChange={(value) => setSource(value)} options={listeSources} />
                </div>

                <ExportSelect
                    className="!font-bold "
                    data={listeDesSources}
                    nomComplet="tiersList.xlsx"
                    nomFichier="tiersList"
                    definitions={definitionsTableau}
                />
            </div>
            <RechercheTable classNames="w-[40%] mt-5 float-right" data={copieData} dataFiltre={(data: any) => setListeDesSources(data)} />
            <Table
                columns={columnsTable}
                dataSource={listeDesSources}
                pagination={{
                    showTotal: (total: any, range: any) => `${range[0]}-${range[1]} / ${total} `,
                    position: ['topRight', 'bottomRight'],
                    total: listeDesSources?.length,
                    defaultCurrent: 1,
                    defaultPageSize: 20,
                }}
            />
        </section>
    );
};

export default DestinataireListPage;
