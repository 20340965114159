/* eslint-disable @typescript-eslint/no-explicit-any */
import FormatDate from 'utils/FormatDate';

///// FORM /////
export interface Close {
    id: number | null;
    fk_tiers: number | null;
    RaisonSociale: string;
    end_date: Date;
    start_date: Date;
    commentaire: string;
}

export const EmptyCloseData: Close = {
    id: null,
    fk_tiers: null,
    RaisonSociale: '',
    end_date: new Date(),
    start_date: new Date(),
    commentaire: '',
};

/** ReFormatData for BackEnd */
export const ReFormatClose = (data: Close) => ({
    id: data.id !== 0 ? data.id : null,
    commentaire: data.commentaire,
    end_date: FormatDate(data.end_date),
    start_date: FormatDate(data.start_date),
    fk_tiers: data.fk_tiers,
});

///// Format Data /////

export interface Closure extends Close {
    Identifiant: string;
    RaisonSociale: string;
    concatAdresse: string;
}

export const FormatClosureList = ({ data }: any): Closure[] =>
    data.map(
        (elem: any): Closure => ({
            Identifiant: elem.Identifiant,
            RaisonSociale: elem.RaisonSociale,
            commentaire: elem.commentaire,
            concatAdresse: elem.concatAdresse,
            end_date: elem.end_date,
            fk_tiers: elem.fk_tiers,
            id: elem.id,
            start_date: elem.start_date,
        }),
    );
