import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';

import { ColumnsType } from 'antd/lib/table';
import { Table, Select } from 'antd';
import API from 'constants/API';
import { GET } from 'utils/Fetch';
import Routes from 'constants/Routes';
import classNames from 'classnames';
import dayjs from 'dayjs';
import { ModelHistoryKey } from 'models/ModelTypeData';

const StatusCle = () => {
    const [statusCle, setStatusCle] = useState<ModelHistoryKey[]>([]);
    const [copieData, setCopieData] = useState<ModelHistoryKey[]>([]);

    const GetStatusCle = async () => {
        const response = await GET(`${API.LAST_HISTORY_KEYS}`);
        if (!response.status) return;
        setStatusCle(response.data);
        setCopieData(response.data);
    };

    function filtreArray(value: any[]) {
        if (value.length === 0) return setStatusCle(copieData);
        const arrayFiltre = [];
        for (let index = 0; index < copieData.length; index++) {
            if (value.includes(copieData[index].fk_etat)) arrayFiltre.push(copieData[index]);
        }
        setStatusCle(arrayFiltre);
    }

    useEffect(() => {
        GetStatusCle();
    }, []);

    const status = { 15: 'Rangé', 16: 'En chargement', 17: 'En livraison', 18: 'Perdu' };
    const statusSelect = [
        {
            value: 15,
            label: 'Rangé',
        },
        {
            value: 16,
            label: 'En chargement',
        },
        {
            value: 17,
            label: 'En livraison',
        },
        {
            value: 18,
            label: 'Perdu',
        },
    ];
    const columns: ColumnsType<ModelHistoryKey> = [
        {
            title: 'Date / Heure',
            sorter: (a, b) => a.date.localeCompare(b.date),
            render: (text, props) => dayjs(props.date).format('DD/MM/YYYY HH:mm:ss'),
            defaultSortOrder: 'descend',
        },
        {
            title: 'Statut',
            render: (text, props) => (
                <span
                    className={classNames('text-sm p-1', {
                        'text-green-500': props.couleur === 'success',
                        'text-purple-600': props.couleur === 'secondary',
                        'text-blue-500': props.couleur === 'info',
                        'text-orange-500': props.couleur === 'warning',
                        'text-red-500': props.couleur === 'error',
                    })}
                >
                    {status[props.fk_etat as keyof typeof status]}
                </span>
            ),
        },
        {
            title: 'Numero de clé',
            render: (text, props) => <span>{props.numero}</span>,
        },
        {
            title: 'Pharmacie',
            render: (text, props) => (
                <span className="flex justify-between">
                    <Link to={`${Routes.COMPANY}/${props.idTiers}`} className="w-full">
                        <p className="text-sm text-purple-900 hover:underline">{props.RaisonSociale}</p>
                    </Link>
                </span>
            ),
        },
        {
            title: 'Dernier utilisateur',
            render: (text, props) => (
                <span className="flex justify-between">
                    <Link to={`${Routes.USER}/${props.idUtilisateur}`} className="w-full">
                        <p className="text-sm text-purple-900 hover:underline">{props.nomPrenom}</p>
                    </Link>
                </span>
            ),
        },
        {
            title: 'Tournée',
            render: (text, props) => (
                <span className="flex justify-between">
                    <Link to={`${Routes.USER}/${props.idTournee}`} className="w-full">
                        <p className="text-sm text-purple-900 hover:underline">{props.Libelle}</p>
                    </Link>
                </span>
            ),
        },
        {
            title: 'Derniere Livraison',
            render: (text, props) => (
                <span className="flex justify-between">
                    <Link to={`${Routes.USER}/${props.fk_livraison}`} className="w-full">
                        <p className="text-sm text-purple-900 hover:underline">{props.fk_livraison}</p>
                    </Link>
                </span>
            ),
        },
    ];

    return (
        <section className="mx-10 my-5">
            <div className="flex flex-col ">
                <span className=" mb-2">Statut des clés</span>
                <Select
                    mode="multiple"
                    className="w-[15%]"
                    onChange={(value: number[]) => {
                        filtreArray(value);
                    }}
                    options={statusSelect}
                />
            </div>
            <Table
                columns={columns}
                dataSource={statusCle}
                pagination={{
                    showTotal: (total: any, range: any) => `${range[0]}-${range[1]} / ${total} `,
                    position: ['topRight', 'bottomRight'],
                    total: statusCle?.length,
                    defaultCurrent: 1,
                    defaultPageSize: 20,
                }}
            />
        </section>
    );
};

export default StatusCle;
