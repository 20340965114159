import React, { useEffect, useContext, useState } from 'react';
import { Link } from 'react-router-dom';
import dayjs from 'dayjs';

import { Button, Space } from 'antd';

import XMLViewer from 'react-xml-viewer';
import ReactJson from 'react-json-view';

import { GET } from 'utils/Fetch';
import API from 'constants/API';
import Routes from 'constants/Routes';
import { NotificationContext } from 'contexts/Notifications';
import Wait from 'components/molecules/Wait';
import { ModelFichier } from 'models/ModelTypeData';

const FichierBrut = () => {
    const [fichier, setFichier] = useState<ModelFichier>();
    const id = window.location.search.split('?')[1];
    const [loading, setLoading] = useState<boolean>(true);
    const { dispatch } = useContext(NotificationContext);

    const GetDocumentBrut = async () => {
        const response = await GET(API.FILE_LOAD + `/${id}`);
        setLoading(false);
        if (!response.status) return dispatch({ message: response.message, status: response.status });
        setFichier(JSON.parse(response.data));
    };

    async function renvoieFichier() {
        const response = await GET(`${API.RENVOI_FICHIER}?id=${id}`);
        return dispatch({ message: response.message, status: response.status });
    }

    useEffect(() => {
        GetDocumentBrut();
    }, []);

    function affichageJSON() {
        if (fichier) return <ReactJson src={fichier?.informations} />;
        return <></>;
    }
    function affichageXML() {
        if (fichier) return <XMLViewer collapsible={true} xml={fichier?.contenu} />;

        return <></>;
    }
    function contentXMD() {
        if (fichier?.contenu) return fichier.contenu;
        return '';
    }
    if (loading) return <Wait height="100%" />;
    return (
        <Space direction="vertical" className="container mx-auto">
            {fichier && (
                <div className="flex justify-between pt-5 pr-10 pl-10">
                    <div className="w-[30%] p-3 border-purple-700 rounded-lg border-2">
                        <span className="text-base uppercase">En provenance de :</span>
                        <Link className=" float-right" to={`${Routes.COMPANY}/${fichier.fk_tiers}`}>
                            <span className=" text-sm w-5/6 text-purple-900 hover:underline">
                                {fichier.RaisonSociale}
                            </span>
                        </Link>
                        <br />
                        <Button
                            onClick={() => renvoieFichier()}
                            form="promotionForm"
                            className="mt-2 float-right  !border-purple-700 !bg-purple-700 !font-bold"
                            type="primary"
                        >
                            Renvoyer le fichier
                        </Button>
                    </div>
                    <div className="w-[30%] p-3 border-purple-700 rounded-lg border-2">
                        <span className="text-base uppercase">Traité le :</span>
                        <span className="float-right">
                            {dayjs(fichier.dateTraitement).format('dddd D MMMM YYYY h:mm ')}
                        </span>
                        <br />
                        <span className="text-base uppercase">Par :</span>
                        <span className="float-right">
                            {fichier.nomPrenomUtilisateur ? fichier.nomPrenomUtilisateur : 'Non précisé'}
                        </span>
                    </div>
                    <div className="w-[20%] p-3 border-purple-700 rounded-lg border-2">
                        <span className="text-base uppercase">Lu : </span>
                        <span className="float-right"> {fichier.informations.nombreDocuments.lu}</span> <br />
                        <span className="text-base uppercase">Ajouté : </span>
                        <span className="float-right"> {fichier.informations.nombreDocuments.ajoute}</span>
                    </div>
                </div>
            )}

            <div className="flex mt-5">
                <div className="w-[50%]">
                    <span className="text-xl font-bold ">JSON</span>
                    <div className="!mt-5">{affichageJSON()}</div>
                </div>
                <div className="w-[50%]">
                    <div className=" mb-2 flex justify-between">
                        <span className="text-xl font-bold mb-5">XML</span>
                        <Button
                            type="primary"
                            className="mt-2 float-right !border-blue-700 !bg-blue-700 !font-bold"
                            onClick={() => navigator.clipboard.writeText(contentXMD())}
                        >
                            Copier le XML dans le press-papier
                        </Button>
                    </div>

                    {affichageXML()}
                </div>
            </div>
        </Space>
    );
};

export default FichierBrut;
