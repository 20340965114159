import React, { useContext, useEffect, useState } from 'react';
import { Link } from 'react-router-dom';

import { ColumnsType } from 'antd/lib/table';
import { Table, Select, Modal, Button } from 'antd';
import API from 'constants/API';
import { GET, POST } from 'utils/Fetch';

import Routes from 'constants/Routes';
import classNames from 'classnames';
import dayjs from 'dayjs';
import { ModelCaisse, ModelSelectList } from 'models/ModelTypeData';
import formatSelect from 'utils/FormatSelect';
import RechercheTable from 'components/organisms/rechercheTable';
import { EditOutlined } from '@ant-design/icons';
import { NotificationContext } from 'contexts/Notifications';

const StatusCaisse = () => {
    const { dispatch } = useContext(NotificationContext);
    const [statusCaisse, setStatusCaisse] = useState<ModelCaisse[]>([]);
    const [copieData, setCopieData] = useState<ModelCaisse[]>([]);
    const [listStatut, setListStatut] = useState<ModelSelectList[]>([]);
    const [open, setOpen] = useState<{ open: boolean; content?: ModelCaisse; newEtat?: number }>({ open: false });
    const GetStatusCaisse = async () => {
        const response = await GET(`${API.LAST_HISTORY_CAISSE}`);
        if (!response.status) return dispatch({ status: response.status, message: response.message });
        setStatusCaisse(response.data);
        setCopieData(response.data);
    };
    const GetlisteEtat = async () => {
        const response = await GET(`${API.OPTIONS_STATUS}`);
        if (!response.status) return dispatch({ status: response.status, message: response.message });
        const filtreCaisse = response.data.filter((e: any) => e.ForType.includes('caisse'));
        setListStatut(formatSelect(filtreCaisse, 'id', 'Libelle', 'Pas de selection'));
    };
    function filtreArray(value: any[]) {
        if (value.length === 0) return setStatusCaisse(copieData);
        const arrayFiltre = [];
        for (let index = 0; index < copieData.length; index++) {
            if (value.includes(copieData[index].fk_etat)) arrayFiltre.push(copieData[index]);
        }
        setStatusCaisse(arrayFiltre);
    }

    function filtreTableauEtat(etat?: number) {
        const tableauFiltre = listStatut.filter(function (item) {
            if (item.value === null || +item.value === etat) return false;
            return true;
        });
        return tableauFiltre;
    }

    async function modiferEtatCaisse() {
        if (!open.newEtat) return dispatch({ status: false, message: 'Vous devez sélectionner un état.' });
        if (open.content && open.newEtat) {
            const data = { idCaisse: open.content.idCaisse, fk_etat: open.newEtat };
            const response = await POST(API.MODIFIER_ETAT_CAISSE, data);
            if (!response.status) return dispatch({ status: response.status, message: response.message });
            GetStatusCaisse();
            setOpen({ open: false });
        }
    }

    useEffect(() => {
        GetStatusCaisse();
        GetlisteEtat();
    }, []);

    const columns: ColumnsType<ModelCaisse> = [
        {
            render: (text, props) => <EditOutlined className="text-green-600 text-xl" onClick={() => setOpen({ open: true, content: props })} />,
        },
        {
            title: 'Date / Heure',
            sorter: (a, b) => a.date.localeCompare(b.date),
            render: (text, props) => dayjs(props.date).format('DD/MM/YYYY HH:mm:ss'),
        },
        {
            title: 'Statut',
            render: (text, props) => (
                <span
                    className={classNames('text-sm p-1', {
                        'text-green-500': props.couleur === 'success',
                        'text-purple-600': props.couleur === 'secondary',
                        'text-blue-500': props.couleur === 'info',
                        'text-orange-500': props.couleur === 'warning',
                        'text-red-500': props.couleur === 'error',
                    })}
                >
                    {props.libelleEtat}
                </span>
            ),
        },
        {
            title: 'Numero caisse',
            render: (text, props) => <span>{props.numeroCaisse}</span>,
        },
        {
            title: 'Pharmacie',
            render: (text, props) => (
                <span className="flex justify-between">
                    <Link to={`${Routes.COMPANY}/${props.fk_tiersDestination}`} className="w-full">
                        <p className="text-sm text-purple-900 hover:underline">{props.tiersDestinationCaisse}</p>
                    </Link>
                </span>
            ),
        },
        {
            title: 'Source',
            render: (text, props) => (
                <span className="flex justify-between">
                    <Link to={`${Routes.COMPANY}/${props.fk_tiersSource}`} className="w-full">
                        <p className="text-sm text-purple-900 hover:underline">{props.tiersSourceCaisse}</p>
                    </Link>
                </span>
            ),
        },
        {
            title: 'Colis/Document',
            render: (text, props) => (
                <>
                    <span className="flex justify-between">
                        Colis
                        <Link to={`${Routes.PACKAGES}/${props.idColis}`} className="w-full ml-2">
                            <p className="text-sm text-purple-900 hover:underline">{props.numeroColis}</p>
                        </Link>
                    </span>
                    <span className="flex justify-between">
                        Document
                        <Link to={`${Routes.PACKAGES}/${props.idColis}`} className="w-full ml-2">
                            <p className="text-sm text-purple-900 hover:underline">{props.numeroDocument}</p>
                        </Link>
                    </span>
                </>
            ),
        },
    ];

    return (
        <section className="mx-10 my-5">
            <Modal
                width={'40%'}
                open={open.open}
                onCancel={() => setOpen({ open: false })}
                footer={[
                    <Button
                        form="promotionForm"
                        className=" !border-blue-700 !bg-blue-700 !font-bold"
                        type="primary"
                        key="back"
                        onClick={() => modiferEtatCaisse()}
                    >
                        Valider
                    </Button>,
                    <Button type="primary" className=" !border-red-700 !bg-red-700 !font-bold" key="back" onClick={() => setOpen({ open: false })}>
                        Retour
                    </Button>,
                ]}
            >
                <div>
                    <h3 className="text-xl mb-5">Sélectionner le nouvel état de la caisse.</h3>
                    <Select
                        value={open.newEtat}
                        className="w-[70%] mb-5"
                        onChange={(value: number) => setOpen({ open: true, content: open.content, newEtat: value })}
                        options={filtreTableauEtat(open.content?.fk_etat)}
                    />
                </div>
            </Modal>
            <div className="flex items-center justify-between mb-5">
                <div className="flex flex-col w-[30%]">
                    <span className=" mb-2">Statut des clés</span>
                    <Select mode="multiple" className="w-[70%]" onChange={(value: number[]) => filtreArray(value)} options={listStatut} />
                </div>
                <RechercheTable classNames="w-[30%]" data={copieData} dataFiltre={(data: any) => setStatusCaisse(data)} />
            </div>
            <Table
                columns={columns}
                dataSource={statusCaisse}
                pagination={{
                    showTotal: (total: any, range: any) => `${range[0]}-${range[1]} / ${total} `,
                    position: ['topRight', 'bottomRight'],
                    total: statusCaisse?.length,
                    defaultCurrent: 1,
                    defaultPageSize: 20,
                }}
            />
        </section>
    );
};

export default StatusCaisse;
