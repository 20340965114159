import React, { useContext, useEffect, useState } from 'react';
import { NotificationContext } from 'contexts/Notifications';
import Link from 'components/atoms/Link';
import dayjs from 'dayjs';

import { ColumnsType } from 'antd/lib/table';
import { Table, Button, Input } from 'antd';
import { SettingOutlined } from '@ant-design/icons';

import { GET } from 'utils/Fetch';
import API from 'constants/API';
import Routes from 'constants/Routes';
import { ModelFichierList } from 'models/ModelTypeData';
import RechercheTable from 'components/organisms/rechercheTable';

const FilesHistory = () => {
    const { dispatch } = useContext(NotificationContext);
    const [filesHistory, setfilesHistory] = useState<ModelFichierList[]>();
    const [recherche, setRecherche] = useState<string>();
    const [copieData, setCopieData] = useState<ModelFichierList[]>([]);

    useEffect(() => {
        GetFiles();
    }, []);

    const GetFiles = async (rechercheXML?: string) => {
        let url = `${API.FILES_HISTORY}`;
        if (rechercheXML) url += `?rechercheXML=${rechercheXML}`;
        const response = await GET(url);
        if (!response.status) return dispatch({ status: response.status, message: response.message });
        setfilesHistory(response.data);
        setCopieData(response.data);
    };

    const columnsTable: ColumnsType<ModelFichierList> = [
        {
            title: 'Tiers',
            sorter: (a, b) => a.RaisonSociale.localeCompare(b.RaisonSociale),
            render: (text, props) => (
                <Link to={`${Routes.COMPANY}/${props.fk_tiers}`}>
                    <p className="text-sm text-left text-purple-900 hover:underline">{props.RaisonSociale}</p>
                </Link>
            ),
        },
        {
            title: 'Fichier',
            sorter: (a, b) => a.filename.localeCompare(b.filename),
            render: (text, props) => (
                <div className="flex">
                    <Link to={`${Routes.FILES_HISTORY}/${props.idFichier}`}>
                        <p className="text-sm text-left text-purple-900 hover:underline">{props.filename}</p>
                    </Link>
                    <Link to={`${Routes.FILES_HISTORYBRUT_ID}?${props.idFichier}`}>
                        <SettingOutlined className="!text-green-600 ml-2 mt-0" />
                    </Link>
                </div>
            ),
        },
        {
            title: 'Date',
            sorter: (a, b) => a.dateTraitement.localeCompare(b.dateTraitement),
            render: (text, props) => dayjs(props.dateTraitement).format('DD/MM/YYYY HH:mm'),
            defaultSortOrder: 'descend',
        },
        {
            title: 'Utilisateur',
            sorter: (a, b) => a.concatNomPrenom.localeCompare(b.concatNomPrenom),
            render: (text, props) => (
                <span className="flex justify-between">
                    <Link to={`${Routes.USER}/${props.fk_utilisateur}`} className="w-full">
                        <p className="text-sm text-purple-900 hover:underline">{props.concatNomPrenom}</p>
                    </Link>
                </span>
            ),
        },
    ];

    return (
        <section className="container mx-auto">
            <div className="flex items-center mt-5">
                <Button form="promotionForm" className="mr-5 !border-purple-700 !bg-purple-700 !font-bold" type="primary" onClick={() => GetFiles(recherche)}>
                    Rechercher dans le contenu
                </Button>
                <div className="w-[100%] flex justify-between">
                    <Input
                        value={recherche}
                        placeholder="Rechercher dans le xml"
                        className="!w-[40%] mr-5"
                        onChange={({ target }) => setRecherche(target.value)}
                        allowClear
                        onClear={() => {
                            setRecherche('');
                            GetFiles();
                        }}
                    />
                    <RechercheTable classNames=" float-right w-[30%]" data={copieData} dataFiltre={(data: any) => setfilesHistory(data)} />
                </div>
            </div>
            <Table
                columns={columnsTable}
                dataSource={filesHistory}
                pagination={{
                    showTotal: (total: any, range: any) => `${range[0]}-${range[1]} / ${total} `,
                    position: ['topRight', 'bottomRight'],
                    total: filesHistory?.length,
                    defaultCurrent: 1,
                    defaultPageSize: 20,
                }}
            />
        </section>
    );
};

export default FilesHistory;
