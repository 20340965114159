export const modelLivraison = {
    LibelleTournee: '',
    concatNomPrenom: '',
    createdAt: '',
    fk_livreur: 0,
    history_livraison: [
        {
            Libelle_fk_etat: '',
            concatNomPrenom: '',
            couleur: '',
            date: '',
        },
    ],
    id: 0,
    list_documents: [
        {
            Libelle_fk_etat: '',
            arrivalTime: '',
            Adresse1: '',
            couleur: '',
            couleurEtatKey: null,
            countColis: 0,
            etatKey: null,
            fk_etat: 0,
            fk_tiersDestination: 0,
            id: 0,
            numero: null,
            openingHours: '',
            ordreLivraison: 0,
            raisonSocialeTiersDestination: '',
            raisonSocialeTiersSource: '',
            numeroKey: null,
            fk_tiersSource: 0,
            concatTiersDestinationVille: '',
            Identifiant: '',
        },
    ],
};

export const modelUserHorairesListe = {
    concatNomPrenom: '',
    fk_livreur: 0,
    id: 0,
    jeudi: '',
    lundi: '',
    mardi: '',
    mercredi: '',
    samedi: '',
    vendredi: '',
};
