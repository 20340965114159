type ObjectSelect = {
    label: string;
    value: any;
};
export default function formatSelect(array: [], id: string, libelle: string, valueVide?: string) {
    const listeSelect: ObjectSelect[] = [];
    if (valueVide)
        listeSelect.push({
            label: valueVide,
            value: null,
        });
    for (let index = 0; index < array.length; index++) {
        listeSelect.push({
            value: array[index][id],
            label: array[index][libelle],
        });
    }
    return listeSelect;
}
