import React, { useEffect, useState } from 'react';

import dayjs from 'dayjs';
import 'dayjs/locale/fr';
import local from 'antd/es/date-picker/locale/fr_FR';

import { ColumnsType } from 'antd/lib/table';
import { Table, Select, DatePicker } from 'antd';

import API from 'constants/API';
import { GET } from 'utils/Fetch';
import { GetlisteEtat } from 'utils/ListeSelect/statuts';
import RechercheTable from 'components/organisms/rechercheTable';
import { ModelLivraisonListe } from 'models/ModelTypeData';
import Routes from 'constants/Routes';
import { Link } from 'react-router-dom';
import classNames from 'classnames';
import recupQuery from 'utils/Queries/recupQueries';
import { ExcelHeaderDefinitions } from 'utils/ExportXLS';
import ExportSelect from 'components/organisms/ExportSelect';

const { RangePicker } = DatePicker;

const definitionsTableau: ExcelHeaderDefinitions = [
    { header: 'Numéro livraison', data: 'idLivraison', type: 'string' },
    { header: 'Tournée', data: 'libelleTournee', type: 'string' },
    { header: 'Livreur', data: 'nomPrenom', type: 'string' },
    { header: 'Date', data: 'date', type: 'date' },
    { header: 'Etat', data: 'LibelleEtat', type: 'string' },
];

const DeliveryList = () => {
    const [periode, setPeriode] = useState<string[]>([dayjs().subtract(1, 'week').format('YYYY-MM-DD'), dayjs().format('YYYY-MM-DD')]);
    const [devilveriesList, setDeliveriesList] = useState<ModelLivraisonListe[]>([]);
    const [copieData, setCopieData] = useState<ModelLivraisonListe[]>([]);
    const [selectListe, setSelectListe] = useState<any[]>([]);
    const [etat, setEtat] = useState<number[]>(+recupQuery('etat') ? [+recupQuery('etat')] : []);
    async function getListeSelect() {
        setSelectListe(await GetlisteEtat('livraison'));
    }

    const GetDeliveryList = async () => {
        let query = `?debutPeriode=${dayjs(periode[0]).format('YYYY-MM-DD  00:00:00')}&finPeriode=${dayjs(periode[1]).format('YYYY-MM-DD  23:59:59')}`;
        if (etat.length !== 0) query += `&fk_etat=${etat}`;
        const response = await GET(`${API.DELIVERIES_LIST}${query}`);
        if (!response.status) return;
        setDeliveriesList(response.data);
        setCopieData(response.data);
    };

    useEffect(() => {
        GetDeliveryList();
    }, [periode, etat]);

    useEffect(() => {
        getListeSelect();
    }, []);

    const columnsTable: ColumnsType<ModelLivraisonListe> = [
        {
            title: 'Numéro livraison',
            sorter: (a, b) => (+a.idLivraison || 0) - (+b.idLivraison || 0),
            render: (text, props) => (
                <Link to={`${Routes.DELIVERY}/${props.idLivraison}`} className="w-full">
                    <p className="text-sm text-purple-900 hover:underline">{props.idLivraison}</p>
                </Link>
            ),
        },
        {
            title: 'Tournée',
            sorter: (a, b) => a.libelleTournee.localeCompare(b.libelleTournee),
            render: (text, props) => (
                <span className="flex justify-between">
                    <Link to={`${Routes.TOUR}/${props.idTournee}`} className="w-full">
                        <p className="text-sm text-purple-900 hover:underline">{props.libelleTournee}</p>
                    </Link>
                </span>
            ),
        },
        {
            title: 'Livreur',
            sorter: (a, b) => a.nomPrenom.localeCompare(b.nomPrenom),
            render: (text, props) => (
                <span className="flex justify-between">
                    <Link to={`${Routes.USER}/${props.idLivreur}`} className="w-full">
                        <p className="text-sm text-purple-900 hover:underline">{props.nomPrenom}</p>
                    </Link>
                </span>
            ),
        },
        {
            title: 'Date',
            sorter: (a, b) => a.date.localeCompare(b.date),
            render: (text, props) => dayjs(props.date).format('DD/MM/YYYY HH:mm'),
            defaultSortOrder: 'descend',
        },
        {
            title: 'État',
            sorter: (a, b) => (+a.idLivraison || 0) - (+b.idLivraison || 0),
            render: (text, props) => (
                <span
                    className={classNames('text-sm p-1', {
                        'text-green-500': props.couleur === 'success',
                        'text-purple-600': props.couleur === 'secondary',
                        'text-blue-500': props.couleur === 'info',
                        'text-orange-500': props.couleur === 'warning',
                        'text-red-500': props.couleur === 'error',
                    })}
                >
                    {props.libelleEtat}
                </span>
            ),
        },
    ];

    return (
        <section className="mx-10 my-5">
            <div className="flex items-end">
                <div className="flex flex-col mr-5">
                    <label className="mr-5">Periode : </label>
                    <RangePicker
                        value={[dayjs(periode[0]), dayjs(periode[1])]}
                        format={'DD-MM-YYYY'}
                        locale={local}
                        onChange={(dates, dateStrings) => {
                            if (dateStrings[0]) setPeriode([dateStrings[0].split('-').reverse().join('-'), dateStrings[1].split('-').reverse().join('-')]);
                            else setPeriode([dayjs().subtract(1, 'years').format('YYYY-MM-DD'), dayjs().format('YYYY-MM-DD')]);
                        }}
                        presets={[
                            { label: "Aujourd'hui", value: [dayjs(), dayjs()] },
                            { label: 'Hier', value: [dayjs().subtract(1, 'days'), dayjs().subtract(1, 'days')] },
                            { label: '7 derniers jours', value: [dayjs().subtract(7, 'days'), dayjs()] },
                            { label: '30 derniers jours', value: [dayjs().subtract(30, 'days'), dayjs()] },
                            { label: '90 derniers jours', value: [dayjs().subtract(90, 'days'), dayjs()] },
                            { label: '12 derniers mois', value: [dayjs().subtract(12, 'month'), dayjs()] },
                            { label: 'Ce mois-ci', value: [dayjs().startOf('month'), dayjs().endOf('month')] },
                            { label: 'Le mois dernier', value: [dayjs().startOf('month').subtract(1, 'month'), dayjs().endOf('month').subtract(1, 'month')] },
                        ]}
                    />
                </div>
                <div className="flex flex-col w-[25%] mr-5">
                    <label className="mr-5">Sélection de l’état : </label>
                    <Select value={etat} mode="multiple" placeholder="Sélection de l’état" onChange={(value) => setEtat(value)} options={selectListe} />
                </div>
                <RechercheTable classNames=" w-[50%] float-right" data={copieData} dataFiltre={(data: any) => setDeliveriesList(data)} />
                <ExportSelect
                    className="!font-bold ml-5"
                    data={devilveriesList}
                    nomComplet="documents_Liste.xlsx"
                    nomFichier="documents_Liste"
                    definitions={definitionsTableau}
                />
            </div>

            <Table
                columns={columnsTable}
                dataSource={devilveriesList}
                pagination={{
                    showTotal: (total: any, range: any) => `${range[0]}-${range[1]} / ${total} `,
                    position: ['topRight', 'bottomRight'],
                    total: devilveriesList?.length,
                    defaultCurrent: 1,
                    defaultPageSize: 20,
                }}
            />
        </section>
    );
};

export default DeliveryList;
