import { Link } from 'react-router-dom';
import React, { Fragment, useContext, useEffect, useState } from 'react';

import { ColumnsType } from 'antd/lib/table';
import { Table } from 'antd';

import Button from 'components/atoms/Buttons';

import API from 'constants/API';
import { DELETE, GET } from 'utils/Fetch';
import Routes from 'constants/Routes';
import AbsentModal from './UsersCalendar/absentModal';
import { FaTrash } from 'react-icons/fa';
import ConfirmDelete from 'pages/CompaniesList/ConfirmDelete';
import { NotificationContext } from 'contexts/Notifications';

import { ModelUserListe } from 'models/ModelTypeData';

const Users = () => {
    const { dispatch } = useContext(NotificationContext);
    // const { authentication, setAuthentication } = useContext(AuthenticationContext);

    const [usersList, setUsersList] = useState<ModelUserListe[]>([]);
    const [absentModal, setAbsentModal] = useState<boolean>(false);
    const [confirmDeleteModal, setConfirmDeleteModal] = useState<boolean>(false);
    const [userId, setUserId] = useState<string>('');

    const GetUserList = async () => {
        const response = await GET(API.USER_LIST);
        if (response.status) setUsersList(response.data);
    };

    const DeleteUser = async (id: string) => {
        const { status, message } = await DELETE(API.USER_PROFIL + '/' + id);
        dispatch({ status, message });
        setConfirmDeleteModal(false);
        GetUserList();
    };

    useEffect(() => {
        GetUserList();
    }, []);

    const columnsTable: ColumnsType<ModelUserListe> = [
        {
            title: 'Nom Prénom',
            sorter: (a, b) => a.concatNomPrenom.localeCompare(b.concatNomPrenom),
            render: (text, props) => (
                <span className="flex justify-between">
                    <Link to={`${Routes.USER}/${props.id}`} className="w-full">
                        <p className="text-sm text-purple-900 hover:underline">{props.concatNomPrenom}</p>
                    </Link>
                    <button
                        type="button"
                        className="outlined-none text-red-600"
                        onClick={() => {
                            setConfirmDeleteModal(true);
                            setUserId(`${props.id}`);
                        }}
                    >
                        <FaTrash />
                    </button>
                </span>
            ),
            defaultSortOrder: 'ascend',
        },
        {
            title: 'Role(s)',
            render: (text, props) => <span className="flex justify-between">{props.concatRoleLibelle}</span>,
        },
        {
            title: 'Centre(s) logistique',
            render: (text, props) => <span className="flex justify-between">{props.concatCentreLogistique}</span>,
        },
    ];

    return (
        <>
            <section className="container mx-auto my-5">
                <div className="flex">
                    <Link to={`${Routes.USER}/0`}>
                        <Button>Ajouter un utilisateur</Button>
                    </Link>
                    <Button variant="outline" onClick={() => setAbsentModal(true)}>
                        Créer une absence
                    </Button>
                </div>
                <Table
                    columns={columnsTable}
                    dataSource={usersList}
                    pagination={{
                        showTotal: (total: any, range: any) => `${range[0]}-${range[1]} / ${total} `,
                        position: ['topRight', 'bottomRight'],
                        total: usersList?.length,
                        defaultCurrent: 1,
                        defaultPageSize: 20,
                    }}
                />
            </section>
            <ConfirmDelete open={confirmDeleteModal} setOpen={setConfirmDeleteModal} id={userId} Delete={DeleteUser} label="user" />
            <AbsentModal open={absentModal} setOpen={setAbsentModal} />
        </>
    );
};

export default Users;
