import Routes from 'constants/Routes';
import HomePage from 'pages/Home';
import CompaniesList from 'pages/CompaniesList';
import TierProfil from 'pages/CompaniesList/Company';
import DocumentsList from 'pages/DocumentsList';
import PackagesList from 'pages/PackagesList';
import _Package from 'pages/PackagesList/_Package';
import UserList from 'pages/UsersList';
import MyAccount from 'pages/myAccount';
import DeliveryList from 'pages/DeliveriesList';
import UserProfil from 'pages/UsersList/UserProfil';
import Documents from 'pages/DocumentsList/Documents';
import Delivery from 'pages/DeliveriesList/Delivery';
import Map from 'pages/Map';
import FilesHistory from 'pages/FilesHistory';
import File from 'pages/FilesHistory/File';
import TourList from 'pages/TourList';
import Tour from 'pages/TourList/Tour';
import { DndProvider } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';
import React from 'react';
import UserSchedule from './UsersList/UserSchedule';
import UsersCalendar from './UsersList/UsersCalendar';
import ClosureList from './CompaniesList/ClosureList';
import DestinataireListPage from './CompaniesList/DestinataireList';
import StatusCle from './CompaniesList/StatusCle';
import FichierBrut from './FilesHistory/ficheBrut';
import StatusCaisse from './CompaniesList/StatusCaisse';

interface AppRoute {
    path: Routes;
    component: () => JSX.Element;
    roles: string[] | [];
}

const DndTour = () => {
    return (
        <DndProvider backend={HTML5Backend}>
            <Tour />
        </DndProvider>
    );
};

const applicationRoutes: AppRoute[] = [
    {
        path: Routes.HOME,
        component: HomePage,
        roles: ['ROLE_ADMIN', 'ROLE_MANAGER'],
    },
    {
        path: Routes.COMPANY,
        component: CompaniesList,
        roles: ['ROLE_ADMIN', 'ROLE_MANAGER'],
    },
    {
        path: Routes.COMPANY_ID,
        component: TierProfil,
        roles: ['ROLE_ADMIN', 'ROLE_MANAGER'],
    },
    {
        path: Routes.COMPANY_CLOSURE,
        component: ClosureList,
        roles: ['ROLE_ADMIN', 'ROLE_MANAGER'],
    },
    {
        path: Routes.DOCUMENTS,
        component: DocumentsList,
        roles: ['ROLE_ADMIN', 'ROLE_MANAGER'],
    },
    {
        path: Routes.DOCUMENTS_ID,
        component: Documents,
        roles: ['ROLE_ADMIN', 'ROLE_MANAGER'],
    },
    {
        path: Routes.PACKAGES,
        component: PackagesList,
        roles: ['ROLE_ADMIN', 'ROLE_MANAGER'],
    },
    {
        path: Routes.PACKAGES_ID,
        component: _Package,
        roles: ['ROLE_ADMIN', 'ROLE_MANAGER'],
    },
    {
        path: Routes.TOUR,
        component: TourList,
        roles: ['ROLE_ADMIN', 'ROLE_MANAGER'],
    },
    {
        path: Routes.TOUR_ID,
        component: DndTour,

        roles: ['ROLE_ADMIN', 'ROLE_MANAGER'],
    },
    {
        path: Routes.DELIVERY,
        component: DeliveryList,
        roles: ['ROLE_ADMIN', 'ROLE_MANAGER'],
    },
    {
        path: Routes.DELIVERY_ID,
        component: Delivery,
        roles: ['ROLE_ADMIN', 'ROLE_MANAGER'],
    },
    {
        path: Routes.MY_ACCOUNT,
        component: MyAccount,
        roles: [],
    },
    {
        path: Routes.USERS,
        component: UserList,
        roles: ['ROLE_ADMIN', 'ROLE_MANAGER'],
    },
    {
        path: Routes.USER_ID,
        component: UserProfil,
        roles: ['ROLE_ADMIN', 'ROLE_MANAGER'],
    },
    {
        path: Routes.USER_SCHEDULE,
        component: UserSchedule,
        roles: ['ROLE_ADMIN', 'ROLE_MANAGER'],
    },
    {
        path: Routes.USER_CALENDAR,
        component: UsersCalendar,
        roles: ['ROLE_ADMIN', 'ROLE_MANAGER'],
    },
    {
        path: Routes.FILES_HISTORY,
        component: FilesHistory,
        roles: ['ROLE_ADMIN'],
    },
    {
        path: Routes.FILES_HISTORY_ID,
        component: File,
        roles: ['ROLE_ADMIN'],
    },
    {
        path: Routes.MAP,
        component: Map,
        roles: [],
    },
    {
        path: Routes.COMPANY_DESTINATAIRE,
        component: DestinataireListPage,
        roles: ['ROLE_ADMIN', 'ROLE_MANAGER'],
    },
    {
        path: Routes.COMPANY_KEY_HISTORY,
        component: StatusCle,
        roles: ['ROLE_ADMIN', 'ROLE_MANAGER'],
    },
    {
        path: Routes.COMPANY_CAISSE_HISTORY,
        component: StatusCaisse,
        roles: ['ROLE_ADMIN', 'ROLE_MANAGER'],
    },
    {
        path: Routes.FILES_HISTORYBRUT_ID,
        component: FichierBrut,
        roles: ['ROLE_ADMIN', 'ROLE_MANAGER'],
    },
];

export default applicationRoutes;
