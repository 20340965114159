import React, { FunctionComponent, Fragment, useEffect, useState, useContext } from 'react';
import { FaMapMarkedAlt } from 'react-icons/fa';

import { LinkOutlined } from '@ant-design/icons';

import { Table } from 'antd';
import { ColumnsType } from 'antd/lib/table';

import Routes from 'constants/Routes';
import DownShift from 'components/atoms/Form/downShift';
import API from 'constants/API';
import { GET, POST } from 'utils/Fetch';
import { NotificationContext } from 'contexts/Notifications';
import { Modal, Button } from 'antd';
import { Link } from 'react-router-dom';
import { ModelDestination } from 'models/ModelTypeData';

interface DestinatairesSourcesProps {
    id: string;
    typeId: string;
}

const DestinatairesSources: FunctionComponent<DestinatairesSourcesProps> = ({ id, typeId }) => {
    const { dispatch } = useContext(NotificationContext);
    const [listeDesSources, setListeDesSources] = useState<ModelDestination[]>([]);
    const [listeDesSourcesSelect, setListeDesSourcesSelect] = useState<any>([]);
    const [selectedTiers, setSelectedTiers] = useState<any>();
    const [open, setOpen] = useState<{ open: boolean; identifiant?: string | null }>({ open: false });

    const GetTiersList = async () => {
        const option = typeId === '1' ? 'source' : 'destinataire';
        let response = await GET(`${API.LIST_TIERS_DES_SOURCES}?type=${option}&id=${id}`);
        if (!response.status) return dispatch({ status: response.status, message: response.message });
        if (option === 'destinataire') setListeDesSources(response.data.destinataire);
        if (option === 'source') setListeDesSources(response.data);
        response = await GET(`${API.LIST_TIERS_DES_SOURCES}?type=source&id=all`);
        if (!response.status) return dispatch({ status: response.status, message: response.message });
        const itemDownShift: any[] = [];
        for (let index = 0; index < response.data.length; index++) {
            itemDownShift.push({
                label: `${response.data[index].RaisonSociale} ${response.data[index].Identifiant}`,
                address: response.data[index].adresseComplete,
                login: response.data[index].Identifiant,
                value: response.data[index],
            });
        }
        setListeDesSourcesSelect(itemDownShift);
    };
    const mouvementTiers = async () => {
        const data = {
            identifiant: open.identifiant,
            newDestinataire: selectedTiers.tiersId,
            idSource: listeDesSources[0].fk_tiersSource,
        };
        const response = await POST(API.MOUVEMENT_TIERS_SOURCE, data);
        setOpen({ open: false });
        GetTiersList();
        setSelectedTiers(undefined);
        return dispatch({ status: response.status, message: response.message });
    };
    useEffect(() => {
        GetTiersList();
    }, []);

    const columns: ColumnsType<ModelDestination> = [
        {
            title: 'Identifiant',
            render: (text, props) => {
                return (
                    <Link to={`${Routes.COMPANY}/${props.tdId}`} className="w-full" target="_blank">
                        <p className="text-sm text-purple-900 hover:underline">{props.Identifiant}</p>
                    </Link>
                );
            },
        },
        {
            title: 'Raison Social destinataire',
            render: (text, props) => {
                return <span className="float-right">{props.RaisonSociale}</span>;
            },
        },
        {
            title: 'Mail destinataire',
            render: (text, props) => {
                return (
                    <a style={{ textDecoration: 'none', color: '#04336d' }} href={`mailto:${props.email}`}>
                        {props.email}
                    </a>
                );
            },
        },
        {
            title: 'Ville destinataire',
            render: (text, props) => {
                return (
                    <span className="flex justify-between">
                        <span>
                            <span className="text-sm pl-2">{props.adresseComplete}</span>
                        </span>

                        <a
                            className="text-sm text-purple-900 hover:underline"
                            target="_blank"
                            href={`https://www.google.com/maps/search/?api=1&query=${props.adresseComplete}`}
                            rel="noreferrer"
                        >
                            <FaMapMarkedAlt className="text-lg" />
                        </a>
                    </span>
                );
            },
        },
        {
            title: 'Commentaire destinataire',
            render: (text, props) => {
                return <span className="float-right">{props.commentaire}</span>;
            },
        },
        {
            title: 'Horaire destinataire',
            render: (text, props) => {
                return (
                    <span>
                        {props.openingHours &&
                            props.openingHours.split(';').map((row, i) => (
                                <p key={i}>
                                    {row}
                                    <br />
                                </p>
                            ))}
                    </span>
                );
            },
        },
    ];

    if (typeId !== '1')
        columns.unshift(
            {
                title: '',
                render: (text, props) => {
                    return (
                        <LinkOutlined
                            placeholder="tes"
                            className="mr-2 !text-blue-700 "
                            onClick={() => setOpen({ open: true, identifiant: props.Identifiant })}
                        />
                    );
                },
            },
            {
                title: 'Source',
                render: (text, props) => {
                    return (
                        <Link to={`${Routes.COMPANY}/${props.fk_tiersSource}`} className="w-full">
                            <p className="text-sm text-purple-900 hover:underline">{props.tsRaisonSociale}</p>
                        </Link>
                    );
                },
            },
        );
    return (
        <section className="mx-10 my-5 w-[100%]">
            <Modal
                closable={false}
                open={open.open}
                footer={[
                    <Button
                        danger
                        key="back"
                        onClick={() => {
                            setSelectedTiers(undefined);
                            setOpen({ open: false });
                        }}
                    >
                        Retour
                    </Button>,
                    <Button type="primary" className=" !border-blue-700 !bg-blue-700" key="back" onClick={() => mouvementTiers()}>
                        valider
                    </Button>,
                ]}
            >
                <>
                    <span>Associer ce tier à une destinaton</span>
                    <div className="flex items-center mb-5 mt-2">
                        <div className="w-[90%] mr-10">
                            {listeDesSourcesSelect.length !== 0 && (
                                <DownShift placeholder="Choisir le tiers" items={listeDesSourcesSelect} onChange={setSelectedTiers} />
                            )}
                        </div>
                    </div>
                </>
            </Modal>
            {listeDesSources.length !== 0 ? (
                <Table className="mt-5" columns={columns} dataSource={listeDesSources} pagination={false} />
            ) : (
                <p>Pas de données dans la base de données</p>
            )}
        </section>
    );
};

export default DestinatairesSources;
